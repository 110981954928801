import { uploadFiles } from "../api/fileApi";

export async function uploadNewFiles(files) {
    let finalList = [];
    let shouldUpload;
    let formData = new FormData();

    for (let i = 0; i < files.length; i++) {
        if (files[i]['filepath']) {
            finalList.push(files[i]);
            continue;
        };
        formData.append("file" + i, files[i]);
        shouldUpload = true;
    }
    if (shouldUpload) {
        let fileData = await uploadFiles(formData);
        finalList.push(...fileData.data);
    }

    return finalList;
}