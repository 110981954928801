import { useEffect, useState } from "react";
import { HashLink as Link} from "react-router-hash-link";
import "./articleOutline.css";
import { RiArticleLine } from "react-icons/ri";
import { BsArrowReturnRight } from "react-icons/bs";
import { BsChatLeftText } from "react-icons/bs";
import { AiOutlineFileText } from "react-icons/ai";

export default function ArticleOutline(props) {

    const titleIconStyle = {
        width: "30px", 
        height: "30px",
        padding: "5px 17px",
        backgroundColor: "var(--accent-color)",
        borderRadius: "10px",
        color: "white",
        margin: "10px",
    }

    const sectionTitleIconStyle = {
        width: "20px",
        height: "20px",
        marginRight: "10px",
    }

    const subSectionIconStyle = {
        width: "20px",
        height: "20px",
        marginRight: "10px",
        color: "var(--dark-gray)",
    }

    return (
        <div className="article-outline-container">
            <div className="outline-title flex-title">
                <RiArticleLine style={titleIconStyle}/><Link to={`/view-article/${props.articleId}#article-title`}>  {props.articleTitle}</Link>
            </div>
            {props.headers.map((header, index, arr) => {
                if (header.nodeName == "H1") {
                    return (    
                        <div className="outline-subtitle flex-title" key={index}>
                            <Link to={`/view-article/${props.articleId}#${header.id}`} key={index}>{header.firstChild.nodeValue}</Link>
                        </div>
                    )
                } else if (header.nodeName == "H2") {
                    return (    
                        <div className="outline-section-title flex-title" key={index}>
                            <Link to={`/view-article/${props.articleId}#${header.id}`} key={index}>{header.firstChild.nodeValue}</Link>
                        </div>
                    )
                } else {
                    return (
                        <div className="outline-section-subtitle flex-title" key={index}>
                            <BsArrowReturnRight style={subSectionIconStyle}/> <Link to={`/view-article/${props.articleId}#${header.id}`} key={index}>{header.firstChild.nodeValue}</Link>
                        </div>
                    )
                }
                
            })}
            {props.showFiles && (<div className="outline-subtitle flex-title">
                <AiOutlineFileText style={subSectionIconStyle}/><Link to={`/view-article/${props.articleId}#article-files`}> Files</Link>
            </div>)}
            {props.showExtras && (<div className="outline-subtitle flex-title">
                <BsChatLeftText style={subSectionIconStyle}/><Link to={`/view-article/${props.articleId}#article-extras`}> Conversation/Extras</Link>
            </div>)}
        </div>
    )

}