import { useState } from "react"
import { useDispatch } from "react-redux";
import { deleteTrello, saveTrello } from "../../../Redux/articlesSlice";
import { setIsLoading } from "../../../Redux/loadingSlice";
import TwoChoiceModal from "../../../Modals/TwoCoiceModal/twoChoiceModal";
import { dateFormatter } from "../../../utils/dateUtils";
import Markdown from "markdown-to-jsx";
import './trelloViewer.css';

export default function TrelloViewer(props) {

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [trelloUrl, setTrelloUrl] = useState("");
    const [trelloIndex, setTrelloIndex] = useState(-1);

    const dispatch = useDispatch();

    const handleSaveTrello = async (trelloUrl) => {
        let data = {
            url: trelloUrl,
            articleId: props.articleId
        }
        let timeout = setTimeout(() => {
            dispatch(setIsLoading(true));
        }, 250);
        await dispatch(saveTrello(data));
        clearTimeout(timeout);
        dispatch(setIsLoading(false));
        setTrelloUrl("");
    }

    const handleDeleteTrello = async (trelloIndex) => {
        let data = {
            trelloIndex: trelloIndex,
            articleId: props.articleId
        }
        let timeout = setTimeout(() => {
            dispatch(setIsLoading(true));
        }, 250);
        await dispatch(deleteTrello(data));
        clearTimeout(timeout);
        dispatch(setIsLoading(false));
        setTrelloIndex(-1);
        setShowDeleteModal(false);
    }
    
    const handleUrlChange = (e) => { 
        setTrelloUrl(e.target.value);
    }

    const getFormattedDescription = (trello) => {
        return `${trello.description.substring(0,150)}`;
    }

    return (
        <div className="trello-container">
            <div className="trello-header">
                <h1>Related Trello Tickets</h1>
                <span className="ticket-input">
                    <input 
                        type="text"
                        placeholder="Enter a valid Trello URL"
                        value={trelloUrl} 
                        onChange={(e) => handleUrlChange(e)} />
                    <button onClick={() => handleSaveTrello(trelloUrl)} className="main-button" style={{textAlign: "center"}}> 
                        Add Trello Ticket 
                    </button>
                </span>
            </div>
            {props.trellos?.map((trello, index) => {
                return (
                    <div className="trello-result-container" key={index}>
                        <div className="header">
                            <div className="header-left">
                                <a href={trello.url} draggable="false" target="_blank" rel="nonreferrer" className="link-container" key={index}>
                                    <div>
                                        <h2>{trello.name}</h2>
                                        <p className="sub-header">Last updated {dateFormatter(trello.lastUpdated)}</p>
                                        <p className="sub-header">{trello.url}</p>
                                    </div>
                                </a>
                            </div>
                            <div className="header-right">
                                <span className="board">{trello.boardName}</span>
                            </div>
                        </div>
                        {trello.description && (<div className="description">
                            <div>
                                <div>
                                    <Markdown>{getFormattedDescription(trello)}</Markdown>
                                </div>
                            </div>
                        </div>)}
                        <div className="options">
                            <button className="main-button" style={{backgroundColor: "var(--error-msg)", borderColor: "var(--error-msg)"}} onClick={(e) => {
                                        setTrelloIndex(index); 
                                        setShowDeleteModal(true);
                                    }}>Remove From Article</button>
                        </div>
                    </div>
                )
            })}
            {showDeleteModal && (
                <TwoChoiceModal
                    title="Confirm Deletion"
                    message="Please confirm you want to permanently delete this Trello ticket from the article."
                    onChoiceOne={() => {
                        setTrelloIndex(-1);
                        setShowDeleteModal(false);
                    }}
                    onChoiceTwo={() => handleDeleteTrello(trelloIndex)}
                    choiceOne="Cancel"
                    choiceTwo="Confirm"
                ></TwoChoiceModal>
            )}
        </div>
    )
}