import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addFavoriteArticleAsync, deleteFavoriteArticleAsync, getFavoriteArticlesAsync } from '../api/userApi';

const initialState = { 
    articleFavorites: localStorage.getItem("articleFavorites") ? JSON.parse(localStorage.getItem("articleFavorites")) : []
}

export const addFavoriteArticle = createAsyncThunk(
    'articles/addFavoriteArticle',
    async (data, thunkApi) => {
        try {
            const res = await addFavoriteArticleAsync(data);
            return res;
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
);

export const deleteFavoriteArticle = createAsyncThunk(
    'articles/deleteFavoriteArticle',
    async (data, thunkApi) => {
        try {
            const res = await deleteFavoriteArticleAsync(data);
            return res;
        } catch(e) {
            console.log(e.stack);
            return thunkApi.rejectWithValue(e);
        }
    }
);

export const getFavoriteArticles = createAsyncThunk(
    'articles/getFavoriteArticles',
    async (data, thunkApi) => {
        try {
            const res = await getFavoriteArticlesAsync(data);
            return res;
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
);

const updateArticleFavorites = (state, action) => {
    localStorage.removeItem('articleFavorites');
    localStorage.setItem("articleFavorites", JSON.stringify([...action.payload.articles]));
    state.articleFavorites= JSON.parse((localStorage.getItem("articleFavorites")));
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(addFavoriteArticle.fulfilled, updateArticleFavorites)
            .addCase(getFavoriteArticles.fulfilled, updateArticleFavorites)
            .addCase(deleteFavoriteArticle.fulfilled, updateArticleFavorites);
    }
})

export const {  } = userSlice.actions;
export default userSlice.reducer;