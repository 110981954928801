import { useDispatch, useSelector } from "react-redux";
import { useState } from "react"
import MultiValueSelector from "../../MultiValueSelector/multiValueSelector";
import SimpleSwitch from "../../SimpleSwitch/simpleSwitch";
import SingleValueSelector from "../../SingleValueSelector/singleValueSelector";
import TwoChoiceModal from "../../../Modals/TwoCoiceModal/twoChoiceModal";
import { updateErrorMessage } from "../../../Redux/messageSlice";

export default function ResourceForm(props) {
    const categories = useSelector((state) => state.categories.values);
    const tags = useSelector((state) => state.tags.values);
    const [didWarnPublic, setDidWarnPublic] = useState(props.resource?.isPublic);
    const [showPublicWarning, setShowPublicWarning] = useState(false);
    const dispatch = useDispatch();

    const handleChange = function(value, key) {
        let temp = {...props.resource};
        temp[key] = value;
        props.onValueChange(temp);
    }

    const onSave = function() {
        try {
            validateArticle(props.resource);
            props.onSave();
        } catch(e) {
            dispatch(updateErrorMessage(e.msg));
        }
    }

    const handlePublicToggle = async (value) => {
        if (!didWarnPublic && value == true) {
            setShowPublicWarning(true);
        } else {
            handleChange(value, "isPublic");
        }
    }

    const validateArticle = function(resource) {
        if (!resource.title || resource.title.length == 0) {
            throw { msg: "Please add a title" }
        }
        if (!resource.category) {
            throw { msg: "Please add a category" }
        }
        if (!resource.tags || resource.tags.length == 0) {
            throw { msg: "Please add at least one tag" }
        }
        if (!resource.url || resource.url.length == 0) {
            throw { msg: "Please add a URL" }
        }
    }

    return (
        <div className="resource-form-container">
            <div className="form-title-container">
                <SimpleSwitch
                    labelOne="Private"
                    labelTwo="Public"
                    target={props.resource?.isPublic}
                    onToggle={handlePublicToggle}
                ></SimpleSwitch>
            </div>
            <h1>{props.title}</h1>
            <div className="form-title-container">
                <input value={props.resource?.title || ''} type="text" placeholder="Title" onChange={(e) => handleChange(e.target.value, 'title')}></input>
            </div>
            <div className="form-field-container">
                <label>Resource URL: </label>
                <input value={props.resource?.url || ''} type="text" onChange={(e) => handleChange(e.target.value, 'url')}></input>
            </div>
            <div className='edit-category-container'>
                <SingleValueSelector
                    options={categories}
                    label="Select Category"
                    displayField="name"
                    onValueChange={(category) => handleChange(category, 'category')}
                    initialValue={props.resource?.category}
                ></SingleValueSelector>
            </div>
            <div className='edit-tags-container'>
                <MultiValueSelector
                    options={tags}
                    label="Select Tags"
                    displayField="name"
                    initialValue={props.resource?.tags}
                    onValueChange={(tags) => handleChange(tags, 'tags')}
                ></MultiValueSelector>
            </div>
            <div className="form-field-container">
                <label>Description: </label>
                <textarea value={props.resource?.description || ''} onChange={(e) => handleChange(e.target.value, 'description')}></textarea>
            </div>
            {props.showOptions && (<div style={{marginTop: "20px", textAlign: "center"}}>
                <button className="main-button" onClick={() => onSave()}>Save</button>  
            </div>)}
            {showPublicWarning && (
                <TwoChoiceModal
                    title="Warning"
                    message="You have set this resource to 'Public'. This article can be viewed by anyone including people outside of the Applitools organization."
                    choiceOne="Undo"
                    choiceTwo="I Understand"
                    onChoiceOne={() => {
                        handleChange(false, "isPublic");
                        setShowPublicWarning(false);
                    }}
                    onChoiceTwo={() => {
                        handleChange(true, "isPublic");
                        setDidWarnPublic(true);
                        setShowPublicWarning(false);
                    }}
                ></TwoChoiceModal>
            )}
        </div>
    )
}