import DOMPurify from 'dompurify';

export default function SlackResults(props) {

    const formatHighlights = (text) => {
        const re1 = /\ue000/gi;
        const re2 = /\ue001/gi;
        text = text
            .replace(re1, "<span style='background-color: yellow'>")
            .replace(re2, '</span>');
        
        text = DOMPurify.sanitize(text);
        return text;
    } 

    return (
        <>
            {props.slacks?.map((slack, index) => {
                return (
                    <a href={slack.permalink} draggable="false" target="_blank" rel="nonreferrer" className="link-container" key={index}>
                        <div className="search-result-container">
                            <div className="header">
                                <div>
                                    <h2>{slack.username}</h2>
                                    <p className="sub-header">{slack.permalink}</p>
                                </div>
                                <span className="category">{slack.channel.is_group || slack.channel.is_channel ? "# " + slack.channel.name : slack.username}</span>
                            </div>
                            <div style={{textAlign: "left", marginBottom: "20px"}}>
                                <span className="tag">{slack.type == "im" ? "Direct Message" : slack.type == "group" ? "Private Group" : "Channel"}</span>
                            </div>
                            <div className="description">
                                {/* <span>{formatHighlights(slack.text)}</span> */}
                                <div
                                    className="comment-text"
                                    dangerouslySetInnerHTML={{ __html: formatHighlights(slack.text)}}
                                />
                            </div>
                        </div>
                    </a>
                )
            })}
        </>
    )
}