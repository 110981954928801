import React, { useState, useEffect } from "react";
import Editor from 'react-markdown-editor-lite';
import "./ViewArticle.css";
import { useNavigate, useParams } from "react-router-dom";
import { HiOutlineArrowLeft } from "react-icons/hi"
import { useSelector, useDispatch } from "react-redux";
import EditArticle from "../../Components/ArticleComponents/EditArticle/editArticle";
import MarkdownViewer from "../../Components/MarkdownViewer/MarkdownViewer";
import {
    editArticle,
    getArticle,
    deleteArticle,
    clearViewingArticle
} from '../../Redux/articlesSlice';
import {
    addFavoriteArticle,
    deleteFavoriteArticle
} from '../../Redux/userSlice';
import {
    fetchCategories, 
} from '../../Redux/categoriesSlice';
import { 
    fetchTags,
} from "../../Redux/tagsSlice";
import GenericModal from "../../Modals/GenericModal/genericModal";
import { removeArticleFromResults } from "../../Redux/searchSlice";
import { setIsLoading } from "../../Redux/loadingSlice";
import FileLister from "../../Components/FileLister/fileLister";
import { deleteFiles, uploadPhoto } from "../../api/fileApi";
import { updateErrorMessage } from "../../Redux/messageSlice";
import { handleForbiddenStatus } from "../../Redux/authSlice";
import CommentViewer from "../../Components/CommentComponents/CommentViewer/commentViewer";
import { uploadNewFiles } from "../../utils/fileUtils";
import { FaLock, FaHeart } from "react-icons/fa";
import { FiHeart } from "react-icons/fi";
import { MdNotificationsActive, MdNotificationsOff } from 'react-icons/md';
import {dateFormatter} from "../../utils/dateUtils.js";
import TrelloViewer from "../../Components/TrelloComponents/TrelloViewer/trelloViewer";
import ZendeskViewer from "../../Components/ZendeskComponents/ZendeskViewer/zendeskViewer";
import { toggleNotificationGroupSub } from "../../Redux/notificationsSlice";
import ArticleOutline from "../../Components/ArticleComponents/ArticleOutline/articleOutline";

function ViewArticle(props) {
    const [showEdit, setShowEdit] = useState(false);
    const [editingArticle, setEditingArticle] = useState({});
    const [modalData, setModalData] = useState({});
    const [editedFileList, setEditedFileList] = useState([]);
    const [filesToDelete, setFilesToDelete] = useState([]);
    const [photoList, setPhotoList] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [articleTab, setArticleTab] = useState("comments");
    const [isFavorite, setIsFavorite] = useState(false);
    
    const isAuth = useSelector((state) => state.auth.isLoggedIn);
    const article = useSelector((state) => state.articles.viewingArticle);
    const categories = useSelector((state) => state.categories.values);
    const tags = useSelector((state) => state.tags.values);
    const comments = useSelector((state) => state.articles.viewingArticleComments);
    const zendeskTickets = useSelector((state) => state.articles.viewingArticleZendeskTickets);
    const trelloTickets = useSelector((state) => state.articles.viewingArticleTrelloTickets);
    const notifsOn = useSelector((state) => state.notifications.articleNotifsOn);
    const articleFavorites = useSelector((state) => state.user.articleFavorites);
    const userId = useSelector((state) => state.auth.userId);

    const dispatch = useDispatch();
    const params = useParams();
    const nav = useNavigate();

    const handleSaveEdits = async function() {
        let timeout = setTimeout(() => {
            dispatch(setIsLoading(true));
        }, 250);
        let editedArticleCopy = {...editingArticle};
        editedArticleCopy.photos = photoList;
        try {
            validateArticle(editedArticleCopy);
            let fileActions = [];
            fileActions.push(uploadNewFiles(editedFileList));
            if (filesToDelete.length > 0)
                fileActions.push(deleteFiles(filesToDelete));
            
            let reqs = await Promise.all(fileActions);
            editedArticleCopy['files'] = reqs[0];
            editedArticleCopy['photos'] = photoList;
        } catch(e) {
            if (e.msg) {
                dispatch(updateErrorMessage(e.msg));
            } else if (e.status == 403) {
                dispatch(handleForbiddenStatus());
            } else {
                dispatch(updateErrorMessage("Error Saving Edits"));
            }
            clearTimeout(timeout);
            dispatch(setIsLoading(false));
            return;
        }
        await dispatch(editArticle(editedArticleCopy));
        clearTimeout(timeout);
        dispatch(setIsLoading(false));
        setShowEdit(false);
        grabHeaders();
    }

    const validateArticle = function(article) {
        if (!article.title || article.title.length == 0) {
            throw { msg: "Please add a title" }
        }
        if (!article.category) {
            throw { msg: "Please add a category" }
        }
        if (!article.tags || article.tags.length == 0) {
            throw { msg: "Please add at least one tag" }
        }
        if (!article.markdown || article.markdown.length == 0) {
            throw { msg: "Please add an article body" }
        }
    }

    const handleDelete = async function(id, index) {
        const primaryAction = {
            callback: handleDeleteConfirm,
            label: "Confirm",
            args: {},
            isPrimary: true,
        };

        const secondaryAction = {
            callback: handleCloseModal,
            label: "Cancel",
            args: {},
        };

        const modalPayload = {
            actions: [secondaryAction, primaryAction],
            title: "Confirm Deletion",
            body: <p>Please confirm you'd like to delete the article titled "{article.title}". This action cannot be undone.</p>,
            showModal: true,
        }
        setModalData(modalPayload);
    }

    const handleCloseModal = async function() {
        setModalData({});
    }

    const handleDeleteConfirm = async function(args) {
        await dispatch(deleteArticle({id: article._id}));
        await dispatch(removeArticleFromResults(article._id));
        nav('/search'); 
    }

    useEffect(async () => {
        const id = params.id;
        // Get cached categories and tags instead of making API call? 
        dispatch(fetchCategories());
        dispatch(fetchTags());
        dispatch(setIsLoading(true));
        dispatch(clearViewingArticle());
        let req = await dispatch(getArticle({id}));
        setIsFavorite(articleFavorites.some(f => f._id == id));
        dispatch(setIsLoading(false));
        if (req.payload?.error) {
            nav('/search');
        }
        grabHeaders();
    }, [])

    const grabHeaders = () => {
        const currHeaders = document.querySelectorAll('#article-content .article-anchor');
        setHeaders([...currHeaders.values()]);
    } 

    const handleShowEdit = async function() {
        setEditedFileList([...article.files]);
        setEditingArticle({...article});
        setShowEdit(true);
    }

    const handleArticleChange = async function(article) {
        setEditingArticle(article);
    }

    // When editing
    const handleRemoveFile = function(file, index) {
        let files = [...editedFileList];
        let deleteCopy = [...filesToDelete];
        if (files.length == 1) {
            files = [];
        } else {
            files.splice(index, 1);
        }
        setEditedFileList(files);
        
        // Only remove from DO if the file has a filepath, meaning it was already uploaded to DO
        if (file.filepath) {
            deleteCopy.push(file);
            setFilesToDelete(deleteCopy);
        }
    }

    const handleAddFile = function(uploadedFiles) {
        let files = [...editedFileList];
        [...uploadedFiles].forEach((file) => {
            files.push(file);
        })
        setEditedFileList(files); 
    }

    const handlePhotoEmbed = async function(file) {
        let timeout = setTimeout(() => {
            dispatch(setIsLoading(true));
        }, 250);
        try {
            let formData = new FormData();
            formData.append("photo", file);
            const req = await uploadPhoto(formData);
            clearTimeout(timeout);
            dispatch(setIsLoading(false));
            let photos = [...photoList];
            photos.push(req.data.photoId);
            await setPhotoList(photos);
            return process.env.REACT_APP_API_URL + '/api/getPhoto/' + req.data.photoId;
        } catch(e) {
            if (e.msg) {
                dispatch(updateErrorMessage(e.msg))
            } else if (e.status == 403) {
                dispatch(handleForbiddenStatus());
            } else {
                console.log(e);
                dispatch(updateErrorMessage("Error Uploading Photo"));
            }
            clearTimeout(timeout);
            dispatch(setIsLoading(false));
        }
    }

    const toggleNotifications = async function() {
        let timeout = setTimeout(() => {
            dispatch(setIsLoading(true));
        }, 250);
        const body = {
            groupId: article._id,
            isSubbed: notifsOn,
        }
        await dispatch(toggleNotificationGroupSub(body));
        clearTimeout(timeout);
        dispatch(setIsLoading(false));
    }

    const toggleIsFavorite = async function() {
        let timeout = setTimeout(() => {
            dispatch(setIsLoading(true));
        }, 250);
        const body = {
            articleId: article._id,
            _id: userId
        }
        await dispatch(isFavorite ? deleteFavoriteArticle(body) : addFavoriteArticle(body));
        await setIsFavorite(!isFavorite);
        clearTimeout(timeout);
        dispatch(setIsLoading(false));
    }
    
    return [
        <div key="article">
            {/* Check for article.title to prevent empty values from flash rendering since {} is truthy and a title is required on an article */}
            {!showEdit && article.title &&  [
                <div className="options-header" key="header" style={{top: document.getElementById("nav").offsetHeight}} id="view-article-subnav">
                    <button className="back-option-button" onClick={() => nav("/search")}><HiOutlineArrowLeft style={{fontSize: "15px", verticalAlign: "middle"}}/> Back To Search</button>
                    {isAuth && <button className="main-button" style={{backgroundColor: "var(--error-msg)", borderColor: "var(--error-msg)"}} onClick={handleDelete}>Delete</button>}
                    {isAuth &&<button className="main-button" onClick={handleShowEdit}>Edit</button>}
                </div>,
                <div className="article-body-container" key="body"> 
                    <ArticleOutline
                        articleId={article._id}
                        headers={headers}
                        articleTitle={article.title}
                        key="outline"
                        showFiles={article.files?.length > 0}
                        showExtras={isAuth}
                    ></ArticleOutline>
                    <div className="view-article-container" id="article-content" key="body">
                        <div className="view-article-header">
                            <div className="article-title-container">
                                <h2 className="view-article-title" id="article-title">{article.title} {!article.isPublic && <span className="internal-flag"><FaLock/></span>}</h2> 
                                <label className="view-article-category">{article.category?.name}</label> 
                            </div>
                            {isAuth && (
                                <div className="user-options">
                                    <button className='user-option' onClick={toggleNotifications}>
                                        {notifsOn ? <MdNotificationsActive className='user-option-icon user-option-icon-on'/> : <MdNotificationsOff className='user-option-icon user-option-icon-off'/>}
                                    </button>
                                    <button className='user-option' onClick={toggleIsFavorite}>
                                        {isFavorite ? <FaHeart className='user-option-icon user-option-icon-on'/> : <FiHeart className='user-option-icon user-option-icon-off'/>}
                                    </button>
                                </div>
                            )}
                            <p style={{fontStyle: "italic", margin: "0px 0px 10px 20px", textAlign: "left"}}>
                                Last updated {dateFormatter(article.updatedAt)}
                            </p>
                            <p style={{fontStyle: "italic", margin: "0px 0px 10px 20px", textAlign: "left"}}>
                                {article.createdBy?.name} ({article.createdBy?.email}) 
                                
                            </p>
                            {article?.tags?.length > 0 && (
                                <div className="tags">
                                    {article.tags.map((tag) => {
                                        return <span key={tag._id}>{tag.name} </span>
                                    })}
                                </div>
                            )}
                        </div>
                        {article?.markdown && (
                            <Editor
                                style={{border: "none"}}
                                value={article.markdown}
                                view={{menu: false, md: false, html: true}}
                                renderHTML={(text) => <MarkdownViewer markdown={text} />}
                            />
                        )}
                    </div>
                </div>,
                <div style={{width: "75%", margin: "0px auto"}} key="files">
                    {article.files?.length > 0 &&<h1 id="article-files">Attached Files</h1>}
                    <FileLister
                        key="files"
                        showDownload={true}
                        files={article.files}
                        reqBaseUrl={process.env.REACT_APP_API_URL + '/api/file/article?path='}
                    ></FileLister>
                </div>,
                <div className="article-extras" key="extras" id="article-extras">
                    {isAuth && (<div className="article-extras-options">
                        <button className={articleTab === 'comments' ? "article-extras-option  selected-extras-option" : "article-extras-option"} onClick={() => setArticleTab('comments')}>Comments</button>
                        <button className={articleTab === 'trellos' ? "article-extras-option  selected-extras-option" : "article-extras-option"} onClick={() => setArticleTab('trellos')}>Trellos</button>
                        <button className={articleTab === 'zendesk' ? "article-extras-option  selected-extras-option" : "article-extras-option"} onClick={() => setArticleTab('zendesk')}>Zendesk Tickets</button>
                    </div>)}
                    {(() => {
                        if (isAuth) {
                            if (articleTab === "comments") {
                                return (
                                    <React.Fragment key="comments">
                                        <CommentViewer
                                            comments={comments}
                                            articleId={article._id}
                                            onCommentOrReply={() => {
                                                if (!notifsOn) {
                                                    toggleNotifications();
                                                }
                                            }}
                                        ></CommentViewer>
                                    </React.Fragment>
                                )
                            } else if (articleTab === "zendesk") {
                                return (
                                    <React.Fragment key="zendesks">
                                        <ZendeskViewer
                                            zendesks={zendeskTickets}
                                            articleId={article._id}
                                        ></ZendeskViewer>
                                    </React.Fragment>
                                )
                            } else {
                                return (
                                    <React.Fragment key="trellos">
                                        <TrelloViewer
                                            trellos={trelloTickets}
                                            articleId={article._id}
                                        ></TrelloViewer>
                                    </React.Fragment>
                                )
                            }
                        } 
                    })()}
                </div>
            ]}
        </div>,
        <div key="edit">
            {showEdit && 
                <EditArticle
                    files={editedFileList}
                    article={editingArticle}
                    categories={categories}
                    tags={tags}
                    onSave={handleSaveEdits}
                    onCancel={() => setShowEdit(false)}
                    onArticleChange={handleArticleChange.bind(this)}
                    onFileUpload={handleAddFile}
                    onFileDelete={handleRemoveFile}
                    onPhotoEmbed={handlePhotoEmbed}
                ></EditArticle>
            }
            {modalData.showModal && (
                <GenericModal
                    modalData={modalData}
                ></GenericModal>
            )}
        </div>       
    ]
}

export default ViewArticle;