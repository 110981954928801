import { useEffect, useState } from "react";
import ResourceForm from "../ResourceForm/resourceForm";

export default function EditResourceModal(props) {

    const [editingResource, setEditingResource] = useState({});
    const [editMade, setEditMade] = useState(false);

    useEffect(() => {
        setEditingResource({...props.resource});
    }, []);

    const handleChange = function(obj) {
        setEditingResource(obj);
        setEditMade(true);
    }

    return (
        <div className="generic-modal-backdrop">
            {console.log(props.resource)}
            <div className="generic-modal-container">
                <div className="generic-modal-header">
                    <h1>Edit Resource</h1>
                </div>
                <div className="generic-modal-body">
                    <ResourceForm
                        resource={editingResource}
                        onValueChange={handleChange}
                    ></ResourceForm>
                </div>
                <div className="generic-modal-footer">
                    <button onClick={props.onClose} className="secondary-button">Cancel</button>
                    <button onClick={() => props.onSave(editingResource, editMade)} className="main-button">Save</button>
                </div>
            </div>
        </div>
    )
}