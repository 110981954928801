import GoogleLogin from 'react-google-login';
import { useDispatch } from 'react-redux';
import { login } from '../../Redux/authSlice';
import "./login.css";

export default function Login() {
    const dispatch = useDispatch();

    const handleLogin = async (data) => {
        await dispatch(login(data));
    }

    return (
        <div className="login-container">
            <h1>Login</h1>
            <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                buttonText="Login With Google"
                onSuccess={handleLogin}
                onFailure={handleLogin}
                cookiePolicy='single_host_origin'
                hostedDomain='applitools.com'
            ></GoogleLogin>
        </div>
    )
}