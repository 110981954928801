export function getTemplates() {
    return [
        "Documentation",
        "Error Message",
        "Interesting Case",
        "Tip",
        "Tutorial"
    ];
}

export function getArticleTemplate(templateValue) {
    let text = "";
    switch(templateValue) {
        case "Documentation":
            text = `# Introduction
                *Briefly describe the purpose of this documentation.*
                # Installation
                *Place any installation instructions here. Be sure to consider both Windows and nix systems.*  
                ## Windows
                ## *nix systems
                # Usage
                *Place any usage instructions here.*
                # Troubleshooting       
                *If applicable, list common problems and how to resolve them.*
                # Resources
                *Link to any resources that may be helpful.*
                1. [Resource 1](https://www.example.com)
                1. [Resource 2](https://www.example.com)
                
            `;
        break;
        case "Error Message":
            text = `# Error Message
                *Document the error message:*

                \`\`\`ERROR_MESSAGE\`\`\`
                # Solution(s)
                *Provide potential solution(s) for the error message.*
                1. Potential resolution 1.
                1. Potential resolution 2
                1. Potential resolution 3
                # Why does this error occur?
                *Describe the context of the error message, including steps to reproduce, and environments/SDKs affected.* 
                # Resources
                *Link to any resources that may be helpful.*
                1. [Resource 1](https://www.example.com)
                1. [Resource 2](https://www.example.com)
            `;
        break;
        case "Interesting Case":
            text = `# Background
                *What makes this case unique?*
                # Example
                *Describe the example here. You can reference tickets, or your own experiences using Applitools products.*
                # Example Code
                [Link to repo](URL_TO_REPO)
                # Resources
                *Link to any resources that may be helpful.*
                1. [Resource 1](https://www.example.com)
                1. [Resource 2](https://www.example.com)
            `;
        break;
        case "Tip":
            text = `# The Problem
                *Describe the problem that your tip solves.*
                # The Solution
                *Provide the tip here, including any relevant code.*
                # Resources
                *Link to any resources that may be helpful.*
                1. [Resource 1](https://www.example.com)
                1. [Resource 2](https://www.example.com)
            `;
        break;
        case "Tutorial":
            text = `# Overview
                *What does this tutorial cover?*
                # Instructions
                *Provide detailed instructions. A user with zero knowledge of the feature should be able to follow these instructions.*
                ## Step 1
                *Step 1 instructions.*
                ## Step 2
                *Step 2 instructions.*
                ## Step 3          
                *Step 3 instructions.*
                # Resources
                *Link to any resources that may be helpful.*
                1. [Resource 1](https://www.example.com)
                1. [Resource 2](https://www.example.com)
            `;
        break;
        default:
            text = `# Summary
                *Summary of Article*
                # Resources
                *Link to any resources that may be helpful.*
                1. [Resource 1](https://www.example.com)
                1. [Resource 2](https://www.example.com)
            `
        break;
    }
    return text.replace(/  +/g, '');
}