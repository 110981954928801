import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericModal from "../../../Modals/GenericModal/genericModal";
import { setIsLoading } from "../../../Redux/loadingSlice";
import { deleteFavoriteArticle } from "../../../Redux/userSlice";
import "./favoriteArticleList.css"

export default function FavoriteArticleList(props) {

    const dispatch = useDispatch();
    const userId = useSelector((state) => state.auth.userId);
    const [modalData, setModalData] = useState({});

    const handleRemoveFavClick = (articleId, index, bodyMessage) => {
        const primaryAction = {
            callback: handleRemoveFavConfirm,
            label: "Confirm",
            args: {
                articleId,
                index
            },
            isPrimary: true,
        };

        const secondaryAction = {
            callback: handleCloseModal,
            label: "Cancel",
            args: {},
        }

        const modalPayload = {
            actions: [secondaryAction, primaryAction],
            title: "Confirm Remove Favorite",
            body: <p>{bodyMessage}</p>,
            showModal: true,
        }
        setModalData(modalPayload);
    }

    const handleCloseModal = async function() {
        setModalData({});
    }

    const handleRemoveFavConfirm = async function(args) {
        const body = {
            articleId: args.articleId,
            _id: userId
        }
        let timeout = setTimeout(() => {
            dispatch(setIsLoading(true));
        }, 250);
        await dispatch(deleteFavoriteArticle(body));
        clearTimeout(timeout);
        dispatch(setIsLoading(false));
        handleCloseModal();
    }
    
    return (
        <div className="favorite-articles-manage">
            {props.articles.map((article, index) => {
                const modalBody = `Please confirm you'd like to remove this article from favorites: ${article.title}`;
                return (
                    <div className="notification-sub" key={article._id}>
                        <div><a href={window.location.origin + "/view-article/" + article._id}>{article.title}</a></div>
                        <div><button className="main-button" onClick={() => handleRemoveFavClick(article._id, index, modalBody)}>Unfavorite</button></div>
                    </div>
                )
            })}
            {modalData.showModal && (
                <GenericModal
                    modalData={modalData}
                ></GenericModal>
            )}
        </div>
    )

}