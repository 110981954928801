import { useState } from "react"
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../../Redux/loadingSlice";
import { updateErrorMessage } from "../../../Redux/messageSlice";
import { deleteReport, updateResourceUrl } from "../../../Redux/resourcesSlice";
import "./brokenLinkReports.css";

export default function BrokenLinkReports(props) {

    const [confirmAction, setConfirmAction] = useState(null);
    const [targetedReport, setTargetedReport] = useState(null);
    const [confirmMessage, setConfirmMessage] = useState(null);
    const [newUrl, setNewUrl] = useState("");
    const dispatch = useDispatch();

    const handleShowConfirm = (action, message, index) => {
        setTargetedReport(index);
        setConfirmMessage(message);
        setConfirmAction(action);
    }

    const handleCancel = () => {
        setTargetedReport(null);
        setConfirmMessage(null);
        setConfirmAction(null);
    }

    const handleConfirm = () => {
        if (confirmAction == "dismiss") {
            dismissReport();
        } else {
            updateUrl();
        }
    }

    const dismissReport = async () => {
        let timeout = setTimeout(() => {
            dispatch(setIsLoading(true));
        }, 250);
        await dispatch(deleteReport(props.reports[targetedReport]._id));
        clearTimeout(timeout);
        dispatch(setIsLoading(false));
        handleCancel();
    }

    const updateUrl = async () => {
        let report = props.reports[targetedReport];
        let url = confirmAction == "new" ? newUrl : report.suggestedLink;
        if (!url) {
            dispatch(updateErrorMessage("Please Enter a URL"));
            return;
        }
        let data = {
            newUrl: url,
            resourceId: report.resource._id,
            reportId: report._id,
        }
        let timeout = setTimeout(() => {
            dispatch(setIsLoading(true));
        }, 250);
        await dispatch(updateResourceUrl(data));
        clearTimeout(timeout);
        dispatch(setIsLoading(false));
        handleCancel();
    }

    return (
        <div className="reports-container">
            <h1 className="admin-title">Broken Link Reports</h1>
            {props.reports?.map((report, index) => {
                return (
                    <div key={index} className="report-object">
                        <h3 className="report-title">{report?.resource?.title}</h3>
                        {targetedReport != index &&  (
                            <>
                                <div>
                                    <p><span className="bold">Broken URL:</span> <a href={report.resource?.url}>{report.resource?.url}</a></p>
                                    <p><span className="bold">Suggested URL:</span> {<a href={report.suggestedLink}>{report.suggestedLink}</a> || "Not Specified"}</p>
                                    <p><span className="bold">Reported By:</span> {report.openedBy?.email}</p>
                                    <p><span className="bold">Status:</span> {report.isResolved ? <span style={{color: "var(--main-color)", fontWeight: "bold"}}>Resolved</span> : <span style={{color: "var(--error-msg)", fontWeight: "bold"}}>Unresolved</span>}</p>
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <button className="secondary-button" onClick={() => handleShowConfirm("dismiss", "Dismiss this report?", index)}>Dismiss Report</button>
                                    {report.suggestedLink && <button className="main-button" onClick={() => handleShowConfirm("suggested", "Update resource to suggested URL: " + report.suggestedLink, index)}>Set Suggested URL</button>}
                                    <button className="main-button" onClick={() => handleShowConfirm("new", "Enter a new URL below: ", index)}>Set New URL</button>
                                </div>
                            </>
                        )}
                        {targetedReport == index && (
                            <>
                                <div style={{textAlign: "center"}}>
                                    <p>{confirmMessage}</p>
                                    {confirmAction == "new" && (
                                        <div>
                                            <input 
                                                placeholder="Enter Valid URL" 
                                                style={{width: "70%", padding: "5px 10px", margin: "10px 0px"}}
                                                onChange={(e) => setNewUrl(e.target.value)}
                                            ></input>
                                        </div>
                                        
                                    )}
                                    <button className="secondary-button" onClick={handleCancel}>Cancel</button>
                                    <button className="main-button" onClick={handleConfirm}>{confirmAction == "new" ? "Save" : "Confirm"}</button>
                                </div>
                                
                            </>
                        )}
                    </div>
                )
            })}
        </div>
    )
}