import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchTagsAsync, addTagAsync, editTagAsync, deleteTagAsync } from '../api/tagsApi';

const initialState = { 
    values: [],
    status: 'idle',
}

export const fetchTags = createAsyncThunk(
    'tags/fetchTags',
    async (data, thunkApi) => {
        try {
            const response = await fetchTagsAsync();
            return response.data;
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const addTag = createAsyncThunk(
    'tags/addTag',
    async (tag, thunkApi) => {
        try {
            const response = await addTagAsync(tag);
            return response;
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const deleteTag = createAsyncThunk(
    'tags/deleteTag',
    async (data, thunkApi) => {
        try {
            const res = await deleteTagAsync(data.id);
            return {
                index: data.index,
                msg: res.msg
            }
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)
export const editTag = createAsyncThunk(
    'tags/editTag',
    async (data, thunkApi) => {
        try {
            const res = await editTagAsync(data.tag);
            return {
                data,
                msg: res.msg
            }
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

const tagSlice = createSlice({
    name: 'tags',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchTags.fulfilled, (state, action) => {
                state.status = 'idle';
                state.values = action.payload;
            })
            .addCase(addTag.fulfilled, (state, action) => {
                state.status = 'idle';
                let newTag = action.payload.data;
                state.values = [...state.values, newTag];
            })
            .addCase(deleteTag.fulfilled, (state, action) => {
                let currArr = [...state.values];
                if (action.payload.index == 0) {
                    currArr.shift();
                } else {
                    currArr.splice(action.payload.index, 1);
                }
                state.values = currArr;
            })
            .addCase(editTag.fulfilled, (state, action) => {
                let currArr = [...state.values];
                currArr[action.payload.data.index] = action.payload.data.tag;
                state.values = currArr;
            })
    }
  })
  
  export const {  } = tagSlice.actions
  export default tagSlice.reducer 