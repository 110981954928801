import { checkStatusCode } from "./utils";

export async function addResourceAsync(data) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/saveResource', {
        method: "POST",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    })
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res;
}

export async function editResourceAsync(data) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/editResource', {
        method: "PUT",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    })
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res;
}

export async function deleteResourceAsync(id) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/deleteResource/' + id, {
        method: "DELETE",
        credentials: "include",
    })
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res;
}

export async function submitBrokenLinkReport(data) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/submitBrokenLink', {
        method: "POST",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    })
    const res = await req.json();
    if (res.error) throw res;
    return res;
}

export async function fetchBrokenReportsAsync() {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/brokenReports', {
        method: "GET",
        credentials: "include",
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res;
}

export async function deleteReportAsync(id) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/brokenReport/'+id, {
        method: "DELETE",
        credentials: "include",
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res;
}

export async function updateResourceUrlAsync(data) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/brokenReport', {
        method: "PUT",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res;
}