import React from 'react';
import "./genericModal.css";
/*
    props:
        modalData: {
            actions: []
            handleClose: function
            Title: string
            Body: Element
        }
    action: {
        callback: function,
        label: string,
        args: object            // The args to be passed to the callback
    }
*/

export default function GenericModal(props) {

    return (
        <div className="generic-modal-backdrop">
            <div className="generic-modal-container">
                <div className="generic-modal-header">
                    <h1>{props.modalData?.title}</h1>
                </div>
                <div className="generic-modal-body">
                    {props.modalData?.body}
                </div>
                <div className="generic-modal-footer">
                    {props.modalData?.actions.map((action, index) => {
                        return <button 
                                    key={action.label + index} 
                                    onClick={() => action.callback(action.args)}
                                    className={action.isPrimary ? "main-button" : "secondary-button"}
                                >{action.label}</button>
                    })}
                </div>
            </div>
        </div>
    )

}