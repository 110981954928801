import { useState } from "react";

export default function BrokenLinkModal(props) {
    const [suggestedLink, setSuggestedLink] = useState("");

    const handleLinkChange = (e) => {
        setSuggestedLink(e.target.value);
    }

    return (
        <div className="generic-modal-backdrop">
            <div className="generic-modal-container">
                <div className="generic-modal-header">
                    <h1>Report Broken Link</h1>
                </div>
                <div className="generic-modal-body">
                    <p>If you have a link that is working, please suggest it below. Otherwise, we will investigate your report on our end and resolve the issue.</p>
                    <input 
                        value={suggestedLink} 
                        placeholder="Suggest a link" 
                        onChange={handleLinkChange}
                        style={{padding: "5px", width: "50%"}}
                    ></input>
                </div>
                <div className="generic-modal-footer">
                    <button onClick={props.onClose} className="secondary-button">Cancel</button>
                    <button onClick={() => props.onSave(suggestedLink)} className="main-button">Submit</button>
                </div>
            </div>
        </div>
    )
}