import "../../ArticleComponents/ListArticles/ListArticles.css";
import DOMPurify from 'dompurify';
import "./zendeskResults.css";
import {dateFormatter} from "../../../utils/dateUtils.js";

const VISIBLE_DESCRIPTION_LENGTH = 250;

export default function ZendeskResults(props) {

    const getParsedAndSanitizedComment = (comment) => {
        // Remove Ellipses from beginning and end of comment string
        comment = comment
                    .replace('...','')
                    .replace('...','\n');

        // Sanitize HTML to prevent XSS (remove tagged elements, escape quotes etc.)
        comment = DOMPurify.sanitize(comment);

        return comment;
    }

    const getFormattedDescription = (ticket) => {
        return `${ticket.description.substring(0,VISIBLE_DESCRIPTION_LENGTH)}...`
    }

    return (
        <>
            {props.tickets?.map((ticket, index) => {
                return (
                    <a href={ticket.url} draggable="false" target="_blank" rel="nonreferrer" className="link-container" key={index}>
                        <div className="search-result-container">
                            <div className="header">
                                <div>
                                    <h2>{ticket.subject}</h2>
                                    <p className="sub-header">Created on {dateFormatter(ticket.created_at)} - {ticket.url}</p>
                                </div>
                                <span className="category">{ticket.requester}</span>
                            </div>
                            <div style={{textAlign: "left", marginBottom: "20px"}}>
                                <span className="tag">{ticket.status}</span>
                            </div>
                            
                            <div className="description">
                            {ticket.comments ? (
                                Object.entries(ticket.comments).map(([id, comments]) => (
                                    <div key={id}>
                                        <div className="commenter"> {ticket.users[comments.commenter_id]} </div>
                                        <div>
                                            {Object.entries(comments.comment).map(([id, comment]) => (
                                                <div
                                                key={id}
                                                className="comment-text"
                                                dangerouslySetInnerHTML={{ __html: getParsedAndSanitizedComment(comment)}}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <span>{getFormattedDescription(ticket)}</span>
                            )}
                            </div>
                            
                            {/* {hoverIndex === ticket.id ? <ZendeskPreviewModal ticket={ticket} /> : ""} */}
                        </div>
                    </a>
                )
            })}
        </>
    )
}
