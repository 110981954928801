import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
    addArticleAsync, 
    deleteArticleAsync, 
    deleteCommentAsync, 
    editArticleAsync,
    getArticleAsync, 
    getMostRecentArticlesAsync,
    saveCommentAsync,
    saveReplyAsync,
    deleteReplyAsync,
    editCommentAsync,
    saveTrelloTicketAsync,
    deleteTrelloTicketAsync,
    saveZendeskTicketAsync,
    deleteZendeskTicketAsync
} from '../api/articlesApi';
import { logout } from "./authSlice";

const initialState = { 
    showResults: false,
    searchQuery: {text: ""},
    viewingArticle: {},
    newArticle: {
        title: ""
    }, 
    recentArticles: [],
    viewingArticleComments: [],
    viewingArticleTrelloTickets: [],
    viewingArticleZendeskTickets: [],
}

export const getArticle = createAsyncThunk(
    'articles/getArticle',
    async (data, thunkApi) => {
        try {
            const response = await getArticleAsync(data.id);
            console.log(response);
            return response;
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const getMostRecentArticles = createAsyncThunk(
    'articles/getMostRecentArticles',
    async (thunkApi) => {
        try {
            const res = await getMostRecentArticlesAsync();
            return res;
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const addArticle = createAsyncThunk(
    'articles/addArticle',
    async(article, thunkApi) => {
        try {
            const response = await addArticleAsync(article);
            return response;
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const deleteArticle = createAsyncThunk(
    'articles/deleteArticle',
    async(data, thunkApi) => {
        try {
            const req = await deleteArticleAsync(data.id);
            return req; 
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const editArticle = createAsyncThunk(
    'articles/editArticle',
    async (data, thunkApi) => {
        try {
            const req = await editArticleAsync(data);
            return {
                data,
                msg: req.msg
            };
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const saveComment = createAsyncThunk(
    'articles/saveComment',
    async (data, thunkApi) => {
        try {
            const req = await saveCommentAsync(data);
            return req;
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)
export const deleteComment = createAsyncThunk(
    'articles/deleteComment',
    async (data, thunkApi) => {
        try {
            const req = await deleteCommentAsync(data.id);
            return {
                data,
                msg: req.msg,
            };
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const saveReply = createAsyncThunk(
    'articles/saveReply',
    async (data, thunkApi) => {
        try {
            const req = await saveReplyAsync(data);
            return {
                index: data.index,
                data: req.data,
                msg: req.msg
            };
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const deleteReply = createAsyncThunk(
    'articles/deleteReply',
    async (data, thunkApi) => {
        try {
            const req = await deleteReplyAsync(data.id);
            return {
                data,
                msg: req.msg
            };
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const editComment = createAsyncThunk(
    'articles/editComment',
    async (data, thunkApi) => {
        try {
            const req = await editCommentAsync(data);
            return {
                data,
                msg: req.msg,
                editAt: req.editDate
            };
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const saveTrello = createAsyncThunk(
    'articles/saveTrello',
    async (data, thunkApi) => {
        try {
            return await saveTrelloTicketAsync(data);
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
);

export const deleteTrello = createAsyncThunk(
    'articles/deleteTrello',
    async (data, thunkApi) => {
        try {
            const req = await deleteTrelloTicketAsync(data);
            return {
                data,
                msg: req.msg
            };
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
);

export const saveZendesk = createAsyncThunk(
    'articles/saveZendesk',
    async (data, thunkApi) => {
        try {
            return await saveZendeskTicketAsync(data);
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
);

export const deleteZendesk = createAsyncThunk(
    'articles/deleteZendesk',
    async (data, thunkApi) => {
        try {
            const req = await deleteZendeskTicketAsync(data);
            return {
                data,
                msg: req.msg
            };
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
);

const articleSlice = createSlice({
    name: 'articles',
    initialState,
    reducers: {
        updateSearchQuery: (state, action) => {
            state.searchQuery = action.payload;
        },
        clearSearchQuery: (state) => {
            state.searchQuery = {text: ""};
        },
        updateNewArticle: (state, action) => {
            state.newArticle = action.payload;
        },
        toggleShowResults: (state, action) => {
            state.showResults = action.payload;
        },
        clearViewingArticle: (state, action) => {
            state.viewingArticle = {}
        },
        addFileToArticle: (state, action) => {
            let temp = {...state.newArticle};
            if (!temp.files) {
                temp.files = [action.payload];
            } else {
                temp.files.push(action.payload);
            }
        },
        addPhotoToNewArticle: (state, action) => {
            let temp = {...state.newArticle};
            if (!temp.photos) {
                temp.photos = [action.payload];
            } else {
                temp.photos.push(action.payload);
            }
            state.newArticle = temp;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(addArticle.fulfilled, (state, action) => {
            state.newArticle = {title: ""};
        })
        .addCase(deleteArticle.fulfilled, (state) => {
            state.viewingArticle = {};
        })
        .addCase(editArticle.fulfilled, (state, action) => {
            state.viewingArticle = action.payload.data;
        })
        .addCase(getArticle.fulfilled, (state, action) => {
            state.viewingArticle = action.payload.data;
            state.viewingArticleComments = action.payload.comments;
            state.viewingArticleTrelloTickets = action.payload.trellos;
            state.viewingArticleZendeskTickets = action.payload.zendesks;
        })
        .addCase(getMostRecentArticles.fulfilled, (state, action) => {
            state.recentArticles = action.payload;
        })
        .addCase(saveComment.fulfilled, (state, action) => {
            let temp = [...state.viewingArticleComments];
            temp.push(action.payload.data);
            state.viewingArticleComments = temp;
        })
        .addCase(deleteComment.fulfilled, (state, action) => {
            let index = action.payload?.data?.index;
            let temp = [...state.viewingArticleComments];
            temp.splice(index, 1);
            state.viewingArticleComments = temp;
        })
        .addCase(saveReply.fulfilled, (state, action) => {
            let index = action.payload?.index;
            let temp = [...state.viewingArticleComments];
            temp[index]['replies'].push(action.payload?.data);
            state.viewingArticleComments = temp;
        })
        .addCase(deleteReply.fulfilled, (state, action) => {
            let index = action.payload?.data?.replyIndex;
            let commentIndex = action.payload.data?.commentIndex;
            let comments = [...state.viewingArticleComments];
            let comment = {...comments[commentIndex]};
            comment.replies.splice(index, 1);
            comments[commentIndex] = comment;
            state.viewingArticleComments = comments;
        })
        .addCase(editComment.fulfilled, (state, action) => {
            const { editedComment, index, replyIndex } = action.payload.data;
            editedComment.editedAt = action.payload.editAt;
            let comments = [...state.viewingArticleComments];
            if (replyIndex > -1) {
                let temp = [...comments[index]['replies']];
                temp[replyIndex] = editedComment;
                comments[index]['replies'] = temp;
                state.viewingArticleComments = comments;
            } else {
                comments[index] = editedComment;
                state.viewingArticleComments = comments;
            }
        })
        .addCase(saveZendesk.fulfilled, (state, action) => {
            state.viewingArticleZendeskTickets = action.payload.data;
        })
        .addCase(deleteZendesk.fulfilled, (state, action) => {
            let temp = [...state.viewingArticleZendeskTickets];
            temp.splice(action.payload.data.index, 1);
            state.viewingArticleZendeskTickets = temp;
            
        })
        .addCase(saveTrello.fulfilled, (state, action) => {
            state.viewingArticleTrelloTickets = action.payload.data;
        })
        .addCase(deleteTrello.fulfilled, (state, action) => {
            let temp = [...state.viewingArticleTrelloTickets];
            temp.splice(action.payload.data.index, 1);
            state.viewingArticleTrelloTickets = temp;
        })
        .addCase(logout.fulfilled, (state, action) => {
            state.viewingArticle = {};
            state.newArticle = { title: ""};
            state.recentArticles = [];
            state.viewingArticleComments = [];
            state.viewingArticleTrelloTickets = [];
            state.viewingArticleZendeskTickets = [];
        })
    }
})

export const { 
    updateSearchQuery, 
    clearSearchQuery, 
    updateNewArticle, 
    toggleShowResults, 
    clearViewingArticle,
    addFileToArticle,
    addPhotoToNewArticle
} = articleSlice.actions
export default articleSlice.reducer 