export default function TwoChoiceModal(props) {
    return (
        <div className="generic-modal-backdrop">
            <div className="generic-modal-container">
                <div className="generic-modal-header">
                    <h1>{props.title}</h1>
                </div>
                <div className="generic-modal-body">
                    {props.message}
                </div>
                <div className="generic-modal-footer">
                    <button onClick={props.onChoiceOne} className="secondary-button">{props.choiceOne}</button>
                    <button onClick={props.onChoiceTwo} className="main-button">{props.choiceTwo}</button>
                </div>
            </div>
        </div>
    )
}