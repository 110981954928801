// Takes in a list of comments and presents them
// allows user to create a new comment and append it to the tree
// Allow user to sort comments by date 

import { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { deleteFiles, uploadFiles } from "../../../api/fileApi";
import TwoChoiceModal from "../../../Modals/TwoCoiceModal/twoChoiceModal";
import { deleteComment, deleteReply, saveComment, saveReply, editComment } from "../../../Redux/articlesSlice";
import { handleForbiddenStatus } from "../../../Redux/authSlice";
import { setIsLoading } from "../../../Redux/loadingSlice";
import { updateErrorMessage } from "../../../Redux/messageSlice";
import { uploadNewFiles } from "../../../utils/fileUtils";
import Comment from "../Comment/comment"
import CommentEditor from "../CommentEditor/commentEditor";

export default function CommentViewer(props) {

    const [showEditor, setShowEditor] = useState(false);
    const [replyingComment, setReplyingComment] = useState(-1);
    const [showModal, setShowModal] = useState(false);
    const [deleteConfirm, setDeleteConfirm] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [editingComment, setEditingComment] = useState(null);
    const email = useSelector((state) => state.auth.email);
    const dispatch = useDispatch();

    const handleSaveComment = async (comment, photos = [], files = []) => {
        if (comment.markdown?.length == 0) {
            return setShowEditor(false);
        }
        let timeout = setTimeout(() => {
            dispatch(setIsLoading(true));
        }, 250);
        let fileData;
        let commentValue = {...comment}; commentValue.photos = photos;
        if (files.length > 0) {
            try {
                let formData = new FormData();
                for (let i = 0; i < files.length; i++) {
                    formData.append("file" + i, files[i]);
                }
                fileData = await uploadFiles(formData);
                commentValue.files = fileData.data;
            } catch(e) {
                if (e.msg) {
                    dispatch(updateErrorMessage(e.msg))
                } else if (e.status == 403) {
                    dispatch(handleForbiddenStatus());
                } else {
                    console.log(e);
                    dispatch(updateErrorMessage("Error Uploading Photo"));
                }
                clearTimeout(timeout);
                dispatch(setIsLoading(false));
                return;
            }
         
        }
        commentValue.createdBy = email; 
        if (replyingComment > -1) {
            let parentId = props.comments[replyingComment]._id;
            let data = {
                comment: commentValue,
                parentId,
                index: replyingComment,
                article: props.articleId,
            }
            await dispatch(saveReply(data));
        } else {
            commentValue.article = props.articleId;
            await dispatch(saveComment(commentValue));
        }
        await props.onCommentOrReply();
        clearTimeout(timeout);
        dispatch(setIsLoading(false));
        setReplyingComment(-1);
        setShowEditor(false);
    }

    const handleReply = (index) => {
        setReplyingComment(index);
        setShowEditor(true);
    }

    const handleDelete = async (callback) => {
        const confirmFunc = () => callback();
        setDeleteConfirm(() => confirmFunc);
        setShowModal(true);
    }

    const handleDeleteComment = async (comment, index) => {
        let timeout = setTimeout(() => {
            dispatch(setIsLoading(true));
        }, 250);
        await dispatch(deleteComment({id: comment._id, index}));
        setShowEditor(false);
        clearTimeout(timeout);
        dispatch(setIsLoading(false));
        setShowModal(false);
    }

    const handleDeleteReply = async (reply, replyIndex, commentIndex) => {
        let data = {
            id: reply._id,
            replyIndex,
            commentIndex
        }
        let timeout = setTimeout(() => {
            dispatch(setIsLoading(true));
        }, 250);
        await dispatch(deleteReply(data));
        clearTimeout(timeout);
        dispatch(setIsLoading(false));
        setShowModal(false);
    }

    const handleEdit = (comment, index, replyIndex = undefined) => {
        let editingInfo = {
            comment,
            index,
            replyIndex
        }
        setEditingComment(editingInfo);
        setIsEdit(true);
        setShowEditor(true);
    }

    const handleSaveEdits = async (editedComment, photos, files, filesToDelete ) => {
        try {
            
            let editedCommentClone = {...editedComment};
            editedCommentClone.photos = photos;

            // upload all new files in filesToUpload and attach to commentObject
            if (files.length > 0) {
                let finalList = await uploadNewFiles(files);
                editedCommentClone.files = finalList;
            } else {
                editedCommentClone.files = [];
            }

            // delete all files in filesToDelete
            if (filesToDelete.length > 0) {
                await deleteFiles(filesToDelete);
            }

            // make request to server with updated comment
            let data = {
                editedComment: editedCommentClone,
                index: editingComment.index,
                replyIndex: editingComment.replyIndex,
            }

            let timeout = setTimeout(() => {
                dispatch(setIsLoading(true));
            }, 250);
            await dispatch(editComment(data));
            clearTimeout(timeout);
            dispatch(setIsLoading(false));
            setShowEditor(false);
            setEditingComment(null);
            setIsEdit(false);

        } catch(e) {

        }
    }

    return (
        <div className="comments-container">
            <div className="comments-header">
                <h1>Conversation</h1>
                <button onClick={() => setShowEditor(true)} className="main-button" style={{textAlign: "center"}}>+ Add Comment</button>
            </div>
            
            {showEditor && (
                <CommentEditor
                    onCancel={() => {
                        setShowEditor(false);
                        setReplyingComment(-1);
                        setIsEdit(false);
                    }}
                    onSave={handleSaveComment}
                    onEdit={handleSaveEdits}
                    isEdit={isEdit}
                    comment={isEdit ? editingComment.comment : null}
                ></CommentEditor>
            )}
            {props.comments?.map((comment, index) => {
                return (
                    <Comment
                        key={index}
                        comment={comment}
                        isTopLevel={true}
                        onDelete={() => handleDelete(() => handleDeleteComment(comment, index))}
                        onReply={() => handleReply(index)}
                        onEdit={() => handleEdit(comment, index)}
                        onEditReply={(reply, replyIndex) => handleEdit(reply, index, replyIndex)}
                        onDeleteReply={(reply, replyIndex) => handleDelete(() => handleDeleteReply(reply, replyIndex, index))}
                    ></Comment>
                )
            })}
            {showModal && (
                <TwoChoiceModal
                    title="Confirm Deletion"
                    message="Please confirm you want to permanently delete this comment"
                    onChoiceOne={() => setShowModal(false)}
                    onChoiceTwo={deleteConfirm}
                    choiceOne="Cancel"
                    choiceTwo="Confirm"
                ></TwoChoiceModal>
            )}
        </div>
    )
}