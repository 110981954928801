import { useState } from "react";
import GenericModal from "../../../Modals/GenericModal/genericModal";
import "../../ManageTags/manageTags.css";

function ManageCategories(props) {
    const [newCategoryName, setNewCategory] = useState("");
    const [editingIndex, setEditingIndex] = useState();
    const [editingValue, setEditingValue] = useState();
    const [modalData, setModalData] = useState({});

    const handleSave = async function() {
        const category = {
            name: newCategoryName
        }
        await props.addCategory(category);
        setNewCategory("");
    }

    const handleEditClick = function(name, index) {
        setEditingIndex(index);
        setEditingValue(name);
    }

    const handleSaveEdits = async function() {
        if (editingValue == props.categories[editingIndex].name) return;

        const category = {...props.categories[editingIndex]};
        category['name'] = editingValue; 

        await props.editCategory(category, editingIndex);
        setEditingIndex(undefined);
        setEditingValue(undefined);
    }

    const handleDelete = async function(id, index) {
        const primaryAction = {
            callback: handleDeleteConfirm,
            label: "Confirm",
            args: {
                index,
                id
            },
            isPrimary: true,
        };
        
        const secondaryAction = {
            callback: handleCloseModal,
            label: "Cancel",
            args: {},
        };

        const modalPayload = {
            actions: [secondaryAction, primaryAction],
            title: "Confirm Deletion",
            body: <p>Please confirm you'd like to remove the "{props.categories[index].name}" tag. This will remove the tag from all existing articles as well.</p>,
            showModal: true,
        }
        setModalData(modalPayload);
    }

    const handleCloseModal = async function() {
        setModalData({});
    }

    const handleDeleteConfirm = async function(args) {
        await props.deleteCategory(args.id, args.index);
        setModalData({});
    }
    return (
        <div className="manage-items-container">
            <input 
                className="create-new" 
                value={newCategoryName} 
                placeholder="Create New Category" 
                onChange={(e) => setNewCategory(e.target.value)}
                onKeyDown={(e) => e.keyCode === 13 && handleSave()} 
            />
            <button className="main-button" onClick={handleSave}>Save</button>
            <div className="options-container">
                {props.categories?.map((category, index) => {
                    return (
                        <div key={category._id} className="option">
                            {index != editingIndex && <label>{category.name}</label>}
                            {index == editingIndex && (
                                <div>
                                    <input 
                                        value={editingValue} 
                                        onChange={(e) => setEditingValue(e.target.value)}
                                        onKeyDown={(e) => e.keyCode === 13 && handleSaveEdits()}    
                                    />
                                    <button onClick={handleSaveEdits}>Save Edits</button>
                                    <button onClick={() => {setEditingIndex(undefined); setEditingValue(undefined)}}>Cancel</button>
                                </div>
                            )}
                            <div>
                                {index != editingIndex && <button onClick={() => handleEditClick(category.name, index)}>Edit</button>}
                                {index != editingIndex && <button onClick={() => handleDelete(category._id, index)}>Delete</button>}
                            </div>
                        </div>
                    )
                })}
            </div>
            {modalData.showModal && (
                <GenericModal
                    modalData={modalData}
                ></GenericModal>
            )}
        </div>
    );
}

export default ManageCategories;