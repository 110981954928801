import { useState } from "react"
import { useDispatch } from "react-redux";
import { deleteZendesk, saveZendesk } from "../../../Redux/articlesSlice";
import { setIsLoading } from "../../../Redux/loadingSlice";
import TwoChoiceModal from "../../../Modals/TwoCoiceModal/twoChoiceModal";
import { dateFormatter } from "../../../utils/dateUtils";
import './zendeskViewer.css';

export default function ZendeskViewer(props) {

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [zendeskUrl, setZendeskUrl] = useState("");
    const [zendeskIndex, setZendeskIndex] = useState(-1);

    const dispatch = useDispatch();

    const handleSaveZendesk = async (zendeskUrl) => {
        let data = {
            url: zendeskUrl,
            articleId: props.articleId
        }
        let timeout = setTimeout(() => {
            dispatch(setIsLoading(true));
        }, 250);
        await dispatch(saveZendesk(data));
        clearTimeout(timeout);
        dispatch(setIsLoading(false));
        setZendeskUrl("");
    }

    const handleDeleteZendesk = async (zendeskIndex) => {
        let data = {
            zendeskIndex: zendeskIndex,
            articleId: props.articleId
        }
        let timeout = setTimeout(() => {
            dispatch(setIsLoading(true));
        }, 250);
        await dispatch(deleteZendesk(data));
        clearTimeout(timeout);
        dispatch(setIsLoading(false));
        setZendeskIndex(-1);
        setShowDeleteModal(false);
    }
    
    const handleUrlChange = (e) => { 
        setZendeskUrl(e.target.value);
    }

    const getFormattedDescription = (zendesk) => {
        return `${zendesk.description.substring(0,400)}...`
    }

    return (
        <div className="zendesk-container">
            <div className="zendesk-header">
                <h1>Related Zendesk Tickets</h1>
                <span className="ticket-input">
                    <input 
                        type="text"
                        placeholder="Enter a valid Zendesk URL"
                        value={zendeskUrl} 
                        onChange={(e) => handleUrlChange(e)} />
                    <button onClick={() => handleSaveZendesk(zendeskUrl)} className="main-button" style={{textAlign: "center"}}> 
                        Add Zendesk Ticket 
                    </button>
                </span>
            </div>
            {props.zendesks?.map((zendesk, index) => {
                return (
                    <div className="zendesk-result-container" key={index}>
                        <div className="header">
                            <a href={zendesk.url} draggable="false" target="_blank" rel="nonreferrer" className="link-container" key={index}>
                                <div>
                                    <h2>{zendesk.title}</h2>
                                    <p className="sub-header">Created on: {dateFormatter(zendesk.createdAt)} </p>
                                    <p className="sub-header">Ticket ID: {zendesk.id} </p>
                                </div>
                            </a>
                        </div>
                        <div style={{textAlign: "left", marginBottom: "20px"}}>
                            <span className="tag">{zendesk.status}</span>
                        </div>
                        <div className="description">
                            <span>{getFormattedDescription(zendesk)}</span>
                        </div>
                        <div className="options">
                            <button className="main-button" style={{backgroundColor: "var(--error-msg)", borderColor: "var(--error-msg)"}} onClick={(e) => {
                                        setZendeskIndex(index); 
                                        setShowDeleteModal(true);
                                    }}>Remove From Article</button>
                        </div>
                    </div>
                );
            })}
            {showDeleteModal && (
                <TwoChoiceModal
                    title="Confirm Deletion"
                    message="Please confirm you want to permanently delete this Zendesk ticket from the article."
                    onChoiceOne={() => {
                        setZendeskIndex(-1);
                        setShowDeleteModal(false);
                    }}
                    onChoiceTwo={() => handleDeleteZendesk(zendeskIndex)}
                    choiceOne="Cancel"
                    choiceTwo="Confirm"
                ></TwoChoiceModal>
            )}
        </div>
    )
}