import { checkStatusCode } from "./utils";

export async function addFavoriteArticleAsync(data) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/user/articleFavorite', {
        method: "POST",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res;   
}

export async function deleteFavoriteArticleAsync(data) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/user/articleFavorite', {
        method: "DELETE",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    });
    checkStatusCode(req);
    const res = await req.json();
    console.log(res);
    if (res.error) throw res;
    return res;   
}

export async function getFavoriteArticlesAsync(data) {
    console.log(data);
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/user/articleFavorite/' + data._id, {
        method: "GET",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res;   
}