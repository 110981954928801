import {FiSquare, FiCheckSquare} from "react-icons/fi"
import "./myCheckbox.css"

export default function MyCheckbox(props) {
    return (
        <div className="mycheckbox">
            <label>{props.label}</label>
            <div className={props.isChecked ? "checkbox-container checkbox-checked" : "checkbox-container checkbox-unchecked"}>
                <div className="checkbox-sub-container" onClick={props.onClick}>
                    <div className="checkbox">
                        {props.isChecked ? <FiCheckSquare/> : <FiSquare/>}
                    </div>
                </div>
            </div>
        </div>
        
    )
}