import DOMPurify from 'dompurify';
import "./docsResults.css";

export default function DocsResults(props) {

    const formatContent = (text) => {
        console.log(text);
        text = DOMPurify.sanitize(text);
        return text;
    }

    return (
        <>
            {props.docs?.map((doc, index) => {
                return (
                    <a href={doc.link} draggable="false" target="_blank" rel="nonreferrer" className="link-container" key={index}>
                        <div className="search-result-container">
                            <div className="header">
                                <div>
                                    <h2>{doc.name}</h2>
                                    <p className="sub-header">{doc.link}</p>
                                </div>
                            </div>
                            <div className="description">
                                <div
                                    className="comment-text"
                                    dangerouslySetInnerHTML={{ __html: formatContent(doc.content)}}
                                />
                            </div>
                        </div>
                    </a>
                )
            })}
        </>
    )
}