import {
    BrowserRouter as Router,
    Route,
    Routes,
    NavLink,
    Navigate
} from "react-router-dom";
import ViewArticle from '../../Screens/ViewArticle/ViewArticle';
import CreateArticle from '../../Screens/CreateArticle/CreateArticle.js';
import Admin from '../../Screens/Admin/admin.js';
import Home from '../../Screens/Home/Home'
import Search from '../../Screens/Search/search.js';
import Login from '../../Screens/Login/login.js';
import Profile from '../../Screens/Profile/profile.js';
import VerifySlack from "../../Screens/VerifySlack/verifySlack";
import { logout } from '../../Redux/authSlice.js';
import GuardedRoute from '../../Components/GuardedRoute/guardedRoute.js';
import CreateResource from '../../Screens/CreateResource/createResource.js';
import { useDispatch, useSelector } from 'react-redux';
import "./navBar.css"
import { IoIosMenu, IoMdArrowForward} from "react-icons/io";
import { useState } from "react";
import NotificationNavOption from "../Notifications/NotificationNavOption/notificationNavOption";

export default function NavBar() {
    const dispatch = useDispatch();
    const isAuth = useSelector((state) => state.auth.isLoggedIn);
    const userType = useSelector((state) => state.auth.userType);
    const [showSlideOut, setShowSlideOut] = useState(false);

    const handleLogout = async () => {
        await dispatch(logout());
    }
    
    return (
        <Router className="browser-nav">
            <div className="nav-container browser-nav" id="nav">
                <div className='left-side'>
                    {isAuth && (
                        <NavLink to="/" style={{textDecoration: "none"}}>
                            <div className="nav-header">
                                Applitools Internal Knowledge Base
                            </div>
                        </NavLink>
                    )}
                    {!isAuth && (
                        <div className="nav-header">
                            Applitools Internal Knowledge Base
                        </div>
                    )}
                </div>
                <div className='right-side'>
                    {isAuth && (
                        <div className="nav-link">
                            <NavLink to="/create-article" className={(navData) => (navData.isActive ? 'selected-link' : 'not-selected-link')}>Create Article</NavLink>
                         </div>
                     )}
                    {isAuth && (
                        <div className="nav-link">
                            <NavLink to="/create-resource" className={(navData) => (navData.isActive ? 'selected-link' : 'not-selected-link')}>Create Resource</NavLink>
                        </div>
                    )}
                    {isAuth && (
                        <div className="nav-link">
                            <NavLink to="/search" className={(navData) => (navData.isActive ? 'selected-link' : 'not-selected-link')}>Search</NavLink>
                        </div>
                    )}
                    {isAuth && userType === "admin" && (
                        <div className="nav-link">
                            <NavLink to="/admin" className={(navData) => (navData.isActive ? 'selected-link' : 'not-selected-link')}>Admin</NavLink>
                        </div>
                    )}
                    {isAuth && (
                        <div className="nav-link">
                            <NavLink to="/profile" className={(navData) => (navData.isActive ? 'selected-link' : 'not-selected-link')}>Profile</NavLink>
                        </div>
                    )}
                    {!isAuth && (
                        <div className="nav-link">
                            <NavLink to="/login" className={(navData) => (navData.isActive ? 'selected-link' : 'not-selected-link')}>Login</NavLink>
                        </div>
                    )}
                    {isAuth && (
                        <div className="nav-link">
                            <NotificationNavOption/>
                        </div>
                    )}
                    {isAuth && (
                        <button className="logout-button" onClick={handleLogout}>Logout</button>
                    )}
                </div>
            </div>
            <div className="nav-container mobile-nav" id="nav">
                <div className='left-side'>
                    <div className="nav-header">
                        Applitools Knowledge Base
                    </div>
                </div>
                <div className="right-side">
                    <div className="hamburger-container" onClick={() => setShowSlideOut(true)}>
                        <IoIosMenu style={{width: "35px", height: "35px"}}/>
                    </div>
                </div>
            </div>
            <div className={showSlideOut ? "slide-out-backdrop" : "hide-side-bar slide-out-backdrop"} onClick={() => setShowSlideOut(false)}>
                <div className={showSlideOut ? "slide-out-container" : "hide-slide-out-container"} onClick={(e) => e.stopPropagation()}>
                    <div className="close-option">
                        <button className="close-button" onClick={() => setShowSlideOut(false)}><IoMdArrowForward style={{width: "35px", height: "35px"}}/></button>
                    </div>
                    {isAuth && (
                        <div className="slide-out-option">
                            <NavLink 
                                to="/" 
                                className={(navData) => (navData.isActive ? 'selected-slide-link' : 'not-selected-slide-link')} 
                                onClick={() => setShowSlideOut(false)}
                            >Home</NavLink>
                        </div>
                    )}
                    
                    {isAuth && (
                        <div className="slide-out-option">
                            <NavLink 
                                to="/create-article" 
                                className={(navData) => (navData.isActive ? 'selected-slide-link' : 'not-selected-slide-link')}
                                onClick={() => setShowSlideOut(false)}
                            >Create Article</NavLink>
                         </div>
                    )}
                    {isAuth && (
                        <div className="slide-out-option">
                            <NavLink 
                                to="/create-resource" 
                                className={(navData) => (navData.isActive ? 'selected-slide-link' : 'not-selected-slide-link')}
                                onClick={() => setShowSlideOut(false)}
                            >Create Resource</NavLink>
                        </div>
                    )}
                    {isAuth && (
                        <div className="slide-out-option">
                            <NavLink 
                                to="/search" 
                                className={(navData) => (navData.isActive ? 'selected-slide-link' : 'not-selected-slide-link')}
                                onClick={() => setShowSlideOut(false)}
                            >Search</NavLink>
                        </div>
                    )}
                    {isAuth && userType === "admin" && (
                        <div className="slide-out-option">
                            <NavLink 
                                to="/admin" 
                                className={(navData) => (navData.isActive ? 'selected-slide-link' : 'not-selected-slide-link')}
                                onClick={() => setShowSlideOut(false)}
                            >Admin</NavLink>
                        </div>
                    )}
                    {isAuth && (
                        <div className="slide-out-option">
                            <NavLink 
                                to="/profile" 
                                className={(navData) => (navData.isActive ? 'selected-slide-link' : 'not-selected-slide-link')}
                                onClick={() => setShowSlideOut(false)}
                            >Profile</NavLink>
                        </div>
                    )}
                    {!isAuth && (
                        <div className="slide-out-option">
                            <NavLink 
                                to="/login" 
                                className={(navData) => (navData.isActive ? 'selected-slide-link' : 'not-selected-slide-link')}
                                onClick={() => setShowSlideOut(false)}
                            >Login</NavLink>
                        </div>
                    )}
                    {isAuth && (
                        <div style={{textAlign: "center"}}>
                            <button className="slide-out-logout-button" onClick={handleLogout}>Logout</button>
                        </div>
                    )}
                </div>
            </div>
            <Routes>
                <Route path="/" element={<GuardedRoute isAuth={isAuth} />}>
                    <Route path="/" element={<Home />} />
                </Route>

                <Route path="/search" element={<GuardedRoute isAuth={isAuth} />}>
                    <Route path="/search" element={<Search />} />
                </Route>

                <Route path="/view-article/:id" element={<GuardedRoute isAuth={isAuth} />}>
                    <Route path="/view-article/:id" element={<ViewArticle />} />
                </Route>
            
                <Route path="/create-article" element={<GuardedRoute isAuth={isAuth} />}>
                    <Route path="/create-article" element={<CreateArticle />}/>
                </Route>
                <Route path="/create-resource" element={<GuardedRoute isAuth={isAuth} />}>
                    <Route path="/create-resource" element={<CreateResource />}/>
                </Route>
                <Route path="/admin" element={<GuardedRoute isAuth={isAuth && userType === "admin"} />}>
                    <Route path="/admin" element={<Admin />} />
                </Route>
                <Route path="/profile" element={<GuardedRoute isAuth={isAuth} />}>
                    <Route path="/profile" element={<Profile />} />
                </Route>
                <Route path="/login" element={<GuardedRoute isAuth={!isAuth} isLogin={true} />}>
                    <Route path="/login" element={<Login />} />
                </Route>
                <Route path="/verifySlack" element={<GuardedRoute isAuth={isAuth}/>}>
                    <Route path="/verifySlack" element={<VerifySlack />} />
                </Route>
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Router>
        )
    }