import { checkStatusCode } from "./utils";

export async function getNotificationsAsync(filterByUnread) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/notifications?filterByUnread=' + filterByUnread, {
        credentials: "include"
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res;
}

export async function toggleNotificationGroupSubAsync(body) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/notificationGroup', {
        method: "PUT",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res;
}

export async function getNotificationSubscriptionsAsync() {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/notificationGroups', {
        credentials: "include"
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res;
}

export async function toggleNotificationStatusAsync(body) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/notification/status', {
        method: "PUT",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res;
}

export async function markAllNotificationsAsReadAsync(body) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/notifications/status', {
        method: "POST",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res;
}
