import "./fileLister.css";
import { getFile } from "../../api/fileApi";
import { useDispatch } from "react-redux";
import { updateErrorMessage } from "../../Redux/messageSlice";
import { handleForbiddenStatus } from "../../Redux/authSlice";
import { FiPaperclip } from "react-icons/fi";

export default function FileLister(props) {
    const dispatch = useDispatch();

    const onDownloadClick = async (file) => {
        try {
            let reqUrl = props.reqBaseUrl + file.filepath;
            let req = await getFile(reqUrl);
            window.open(req.url, "_blank");
        } catch(e) {
            if (e.msg) {
                dispatch(updateErrorMessage(e.msg));
            } else if (e.status == 403) {
                dispatch(handleForbiddenStatus());
            } else {
                dispatch(updateErrorMessage("Error Getting File"));
            }
        }
    }

    return (
        <div className="file-lister-container">
            {props.files?.map((file, index) => {
                return (
                    <div className="file-object" key={index}>
                        {props.showDownload 
                            &&  file.downloadUrl 
                            &&  <button onClick={() => onDownloadClick(file)} style={{backgroundColor: "inherit", padding: "0px"}}>
                                    <FiPaperclip style={{fontSize: "17px", color: "var(--accent-color)", verticalAlign: "middle"}} /> {file.name}
                                </button>}
                        {!props.showDownload && <span><FiPaperclip style={{fontSize: "17px", color: "var(--accent-color)", verticalAlign: "middle"}} /> {file.name}</span>}
                        {!props.showDownload && <button style={{marginLeft: "5px"}} onClick={() => props.onDelete(file, index)}>X</button>}
                    </div>
                )
            })}
        </div>
    )

}