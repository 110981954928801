import { useState, useRef } from "react"
import Editor, { Plugins }  from "react-markdown-editor-lite";
import { useDispatch } from "react-redux";
import { uploadPhoto } from "../../../api/fileApi";
import { handleForbiddenStatus } from "../../../Redux/authSlice";
import { setIsLoading } from "../../../Redux/loadingSlice";
import { updateErrorMessage } from "../../../Redux/messageSlice";
import FileLister from "../../FileLister/fileLister";
import MarkdownViewer from "../../MarkdownViewer/MarkdownViewer";
import { FaExpand } from "react-icons/fa";
import "../comment-styles.css"


export default function CommentEditor(props) {

    const [isLarge, setIsLarge] = useState(false);
    const [comment, setComment] = useState(!props.isEdit ? {
        markdown: "",
    } : {...props.comment});
    const [files, setFiles] = useState(!props.isEdit ? [] : [...props.comment.files]);
    const [photos, setPhotos] = useState(!props.isEdit ? [] : [...props.comment.photos]);
    const [filesToDelete, setFilesToDelete] = useState([]);
    const photosRef = useRef([]);
    photosRef.current = photos;
    const dispatch = useDispatch();

    const handleChange = function(value, key) {
        let temp = {...comment};
        temp[key] = value;
        setComment(temp);
    }

    const handlePhotoEmbed = async (file) => {
        let timeout = setTimeout(() => {
            dispatch(setIsLoading(true));
        }, 250);
        try {
            let formData = new FormData();
            formData.append("photo", file);
            const req = await uploadPhoto(formData);
            clearTimeout(timeout);
            dispatch(setIsLoading(false));

            let temp = [...photosRef.current];
            temp.push(req.data.photoId);
            setPhotos(temp);

            return process.env.REACT_APP_API_URL + '/api/getPhoto/' + req.data.photoId;
        } catch(e) {
            if (e.msg) {
                dispatch(updateErrorMessage(e.msg))
            } else if (e.status == 403) {
                dispatch(handleForbiddenStatus());
            } else {
                console.log(e);
                dispatch(updateErrorMessage("Error Uploading Photo"));
            }
            clearTimeout(timeout);
            dispatch(setIsLoading(false));
        }
    }

    const handleFileAttach = function(value) {
        const fileData = value.target.files;
        let iterableFiles = [...fileData];

        // creating new comment, 
        if (fileData.length > 0) {
            let temp = [...files];
            temp.push.apply(temp, iterableFiles);
            setFiles(temp);
        }
        value.target.value = null;
    }

    const handleFileDelete = function(file, index) {
        let temp = [...files];
        let fileObj = temp[index];
        if (temp.length == 1) {
            temp = [];
        } else {
            temp.splice(index, 1);
        }
        setFiles(temp);

        // If it's an edit and you're removing a previously uploaded file that already has a D.O. filepath then mark it for removal
        if (props.isEdit && fileObj.filepath) {
            let temp = [...filesToDelete];
            temp.push(fileObj);
            setFilesToDelete(temp);
        }
    }

    return (
        <div className="comment-editor-container">
            <div style={{textAlign: "right"}}><button className="comment-editor-expand" onClick={() => setIsLarge(!isLarge)}><FaExpand></FaExpand></button></div>
            <div style={{width: "70%", textAlign: "center", margin: "20px auto"}}>
                <div className='file-upload-container'>
                    <label>Attach Files: </label><input type="file" onChange={handleFileAttach} style={{border: "none", textAlign: "center"}} multiple/>
                </div>
                <FileLister
                    files={files}
                    showDownload={false}
                    onDelete={handleFileDelete}
                ></FileLister>
            </div>
            <Editor
                style={isLarge ? {height: "70vh", margin: "50px 50px", textAlign: "left"} : {height: "250px", margin: "50px 50px", textAlign: "left" }}
                value={comment.markdown}
                onChange={({html, text}) => handleChange(text, 'markdown')} 
                view={{menu: true, md: true, html: true}}
                renderHTML={(text) => <MarkdownViewer markdown={text} />}
                onImageUpload={(file) => handlePhotoEmbed(file)}
                imageAccept=".jpg,.png,.jpeg"
            />
            
            
            <button className="secondary-button" onClick={props.onCancel}>Cancel</button>
            {!props.isEdit && <button className="main-button" onClick={() => props.onSave(comment, photos, files)}>Save Comment</button>}
            {props.isEdit && <button className="main-button" onClick={() => props.onEdit(comment, photos, files, filesToDelete)}>Save Edits</button>}
        </div>
    )
}