import { checkStatusCode } from "./utils";

export async function uploadFiles(data) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/uploadFiles', {
        method: "POST",
        credentials: "include",
        body: data,
    })
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res;
}

export async function deleteFiles(data) {
    let body = {
        files: data,
    }
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/deleteFiles', {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify(body),
    })
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res;
}

export async function uploadPhoto(data) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/uploadPhoto', {
        method: "POST",
        credentials: "include",
        body: data,
    })
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res;
}

export async function getFile(url) {
    const req = await fetch(url, {
        credentials: "include"
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res;
}