import React, { useEffect } from 'react';
import './App.css';

import MessageReporter from './Components/MessageReporter/messageReporter.js';
import LoadingSpinner from './Components/LoadingSpinner/loadingSpinner.js';
import NavBar from './Components/Navbar/navBar.js';

function App() {
  return [
    <NavBar key="nav"></NavBar>,
    <MessageReporter
      key="errors"
    ></MessageReporter>,
    <LoadingSpinner key="loading"></LoadingSpinner>
  ];
}

export default App;
