import MultiValueSelector from "../../MultiValueSelector/multiValueSelector";

export default function TrelloQuery(props) {

    const handleValueChange = (value, key) => {
        props.handleChange(key, value);
    }

    return (
        <div>
            <MultiValueSelector
                options={props.options.trelloBoards}
                label="Select Boards"
                displayField="label"
                onValueChange={(value) => handleValueChange(value, 'boards')}
                initialValue={props.query ? props.query.boards : null}
            ></MultiValueSelector>
        </div>
    )
}