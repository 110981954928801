import SimpleSwitch from "../../SimpleSwitch/simpleSwitch";
import { setIsLoading } from "../../../Redux/loadingSlice";
import { toggleGlobalNotificationSetting } from "../../../Redux/authSlice";
import "../notification-styles.css"
import { useState } from "react";
import GenericModal from "../../../Modals/GenericModal/genericModal";
import { toggleNotificationGroupSub } from "../../../Redux/notificationsSlice";
const { useDispatch } = require("react-redux");

export default function NotificationSettings(props) {

    const dispatch = useDispatch();
    const [modalData, setModalData] = useState({});

    const handleToggle = async (key) => {
        let timeout = setTimeout(() => {
            dispatch(setIsLoading(true));
        }, 250);
        let body = {
            key,
            currValue: props.settings[key]
        }
        await dispatch(toggleGlobalNotificationSetting(body))
        clearTimeout(timeout);
        dispatch(setIsLoading(false));
    }

    const handleUnsubClick = (groupId, index, bodyMessage) => {
        const primaryAction = {
            callback: handleUnsubConfirm,
            label: "Confirm",
            args: {
                groupId,
                index
            },
            isPrimary: true,
        };

        const secondaryAction = {
            callback: handleCloseModal,
            label: "Cancel",
            args: {},
        }

        const modalPayload = {
            actions: [secondaryAction, primaryAction],
            title: "Confirm Unsubscribe",
            body: <p>{bodyMessage}</p>,
            showModal: true,
        }
        setModalData(modalPayload);
    }

    const handleCloseModal = async function() {
        setModalData({});
    }

    const handleUnsubConfirm = async function(args) {
        const body = {
            groupId: args.groupId._id,
            isSubbed: true,
        }
        let timeout = setTimeout(() => {
            dispatch(setIsLoading(true));
        }, 250);
        await dispatch(toggleNotificationGroupSub(body));
        clearTimeout(timeout);
        dispatch(setIsLoading(false));
        handleCloseModal();
    }

    return (
        <div className="notification-settings">
            <h2>Global Notifications</h2>
            <div className="notification-setting">
                <label>New Article Created</label>
                <SimpleSwitch
                    target={props.settings.article_created}
                    onToggle={() => handleToggle("article_created")}
                    labelOne="Off"
                    labelTwo="On"
                ></SimpleSwitch>
            </div>
            <div className="notification-setting">
                <label>New Resource Created</label>
                <SimpleSwitch
                    target={props.settings.resource_created}
                    onToggle={() => handleToggle("resource_created")}
                    labelOne="Off"
                    labelTwo="On"
                ></SimpleSwitch>
            </div>
            <h2>Notification Subscriptions</h2>
            <div className="notification-subscriptions">
                {props.subscriptions.map((sub, index) => {
                    if (sub.onModel == 'Article') {
                        const modalBody = `Please confirm you'd like to unsubscribe from notifications for the article titled: ${sub.groupId.title}`;
                        return (
                            <div className="notification-sub" key={sub._id}>
                                <div><a href={window.location.origin + "/view-article/" + sub.groupId._id}>{sub.groupId.title}</a></div>
                                <div><button className="main-button" onClick={() => handleUnsubClick(sub.groupId, index, modalBody)}>Unsubscribe</button></div>
                            </div>
                        )
                    }
                })}
            </div>
            {modalData.showModal && (
                <GenericModal
                    modalData={modalData}
                ></GenericModal>
            )}
        </div>
    )
}