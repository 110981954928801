import { checkStatusCode } from './utils';

export async function loginAsync(data) {
    const req = await fetch(process.env.REACT_APP_API_URL + "/api/login", {
        method: "POST",
        body: JSON.stringify({token: data.tokenId}),
        credentials: 'include',
        headers: {
            "Content-Type": "application/json"
        }
    })
    const res = await req.json();
    if (res.error) throw res;
    return res; 
}

export async function logoutAsync() {
    const req = await fetch(process.env.REACT_APP_API_URL + "/api/logout", {
        method: "GET",
        credentials: 'include',
    })
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res; 
}

export async function verifySlackAsync(body) {
    const req = await fetch(process.env.REACT_APP_API_URL + "/api/verifySlack", {
        method: "POST",
        credentials: 'include',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res; 
}

export async function revokeSlackAsync() {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/revokeSlack', {
        method: "DELETE",
        credentials: "include"
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res; 
}

export async function toggleGlobalNotificationSettingAsync(body) {
    const req = await fetch(process.env.REACT_APP_API_URL + "/api/user/notificationSettings", {
        method: "PUT",
        body: JSON.stringify(body),
        credentials: 'include',
        headers: {
            "Content-Type": "application/json"
        }
    })
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res; 
}