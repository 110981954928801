import Editor, { Plugins } from 'react-markdown-editor-lite';
import { useState } from "react"
import "./editArticle.css";
import 'react-markdown-editor-lite/lib/index.css';
import CodeBlockDropdown from './Plugins/CodeBlockDropdown';
import MarkdownViewer from '../../MarkdownViewer/MarkdownViewer';
import SingleValueSelector from '../../SingleValueSelector/singleValueSelector';
import MultiValueSelector from '../../MultiValueSelector/multiValueSelector';
import FileLister from '../../FileLister/fileLister';
import SimpleSwitch from '../../SimpleSwitch/simpleSwitch';
import TwoChoiceModal from '../../../Modals/TwoCoiceModal/twoChoiceModal';
import { getTemplates, getArticleTemplate } from '../../../utils/articleUtils';

Editor.unuse(Plugins.FontUnderline);
Editor.unuse(Plugins.Clear);
Editor.unuse(Plugins.FontStrikethrough);
Editor.unuse(Plugins.BlockQuote);
Editor.unuse(Plugins.BlockCodeBlock);
Editor.unuse(Plugins.BlockCodeInline);
Editor.use(Plugins.FullScreen);
Editor.use(CodeBlockDropdown, {});

function EditArticle(props) {

    const [didWarnPublic, setDidWarnPublic] = useState(props.article.isPublic);
    const [showPublicWarning, setShowPublicWarning] = useState(false);
    const [showTemplateWarning, setShowTemplateWarning] = useState(false);
    const [templateValue, setTemplateValue] = useState("");

    const onSave = async function() { 
        await props.onSave();
    }

    const handleChange = function(value, key) {
        let temp = {...props.article};
        temp[key] = value;
        props.onArticleChange(temp);
    }

    const handleFileChange = function(value) {
        const fileData = value.target.files;
        if (fileData.length > 0) {
            props.onFileUpload(fileData);
        }
        value.target.value = null;
    }

    const handleImageUpload = async (file) => {
        return props.onPhotoEmbed(file);
    };

    const handlePublicToggle = async (value) => {
        if (!didWarnPublic && value == true) {
            setShowPublicWarning(true);
        } else {
            handleChange(value, "isPublic");
        }
    }

    const handleTemplateChange = function (value) {
        let text = getArticleTemplate(value);
        setTemplateValue(text);
        setShowTemplateWarning(true);
    }

    return (
        <div>
            <div className='edit-title-container'>
                <SimpleSwitch
                    labelOne="Private"
                    labelTwo="Public"
                    target={props.article.isPublic}
                    onToggle={handlePublicToggle}
                ></SimpleSwitch>
            </div>
            <div className='edit-title-container'>
                <input value={props.article.title} type="text" placeholder="Title" onChange={(e) => handleChange(e.target.value, 'title')}/>
            </div>
            <div className='edit-dropdown-container'>
                <SingleValueSelector
                    options={props.categories}
                    label="Select Category"
                    displayField="name"
                    onValueChange={(category) => handleChange(category, 'category')}
                    initialValue={props.article.category}
                ></SingleValueSelector>
            </div>
            <div className='edit-dropdown-container'>
                <MultiValueSelector
                    options={props.tags}
                    label="Select Tags"
                    displayField="name"
                    initialValue={props.article.tags}
                    onValueChange={(tags) => handleChange(tags, 'tags')}
                ></MultiValueSelector>
            </div>
            <div className='edit-dropdown-container'>
                <SingleValueSelector
                    options={getTemplates()}
                    label="Select Template (Optional)"
                    onValueChange={(templates) => handleTemplateChange(templates)}
                    initialValue={templateValue}
                ></SingleValueSelector>
            </div>
            <div className='file-upload-container'>
                <label>Attach Files: </label><input type="file" onChange={handleFileChange} style={{border: "none"}} multiple/>
            </div>
            <div style={{width: "50%", margin: "0px auto"}}>
                <FileLister
                    files={props.files}
                    showDownload={false}
                    onDelete={props.onFileDelete}
                ></FileLister>
            </div>
            
            <Editor
                value={props.article.markdown}
                style={{ height: '500px'}}
                onChange={({html, text}) => handleChange(text, 'markdown')} 
                renderHTML={(text) => <MarkdownViewer markdown={text} />}
                onImageUpload={handleImageUpload}
                imageAccept=".jpg,.png,.jpeg"
            />
            <div style={{marginTop: "20px", textAlign: "center"}}>
                {props.onCancel && <button className="secondary-button" onClick={props.onCancel}>Cancel</button>  }
                <button className="main-button" onClick={() => onSave()}>Save</button>  
            </div>
            {showTemplateWarning && (
                <TwoChoiceModal
                    title="Warning"
                    message="Selecting a template will overwrite existing markdown. Are you sure you want to continue?"
                    choiceOne="Undo"
                    choiceTwo="Overwrite markdown"
                    onChoiceOne={() => {
                        setShowTemplateWarning(false);
                        setTemplateValue("");
                    }}
                    onChoiceTwo={() => {
                        handleChange(templateValue, 'markdown');
                        setShowTemplateWarning(false);
                    }}
                ></TwoChoiceModal>
            )}
            {showPublicWarning && (
                <TwoChoiceModal
                    title="Warning"
                    message="You have set this article to 'Public'. This article can be viewed by anyone including people outside of the Applitools organization."
                    choiceOne="Undo"
                    choiceTwo="I Understand"
                    onChoiceOne={() => {
                        handleChange(false, "isPublic");
                        setShowPublicWarning(false);
                    }}
                    onChoiceTwo={() => {
                        handleChange(true, "isPublic");
                        setDidWarnPublic(true);
                        setShowPublicWarning(false);
                    }}
                ></TwoChoiceModal>
            )}
        </div>
    );
}

export default EditArticle;