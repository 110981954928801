import { useEffect } from "react";
import MultiValueSelector  from "../../MultiValueSelector/multiValueSelector";

export default function SlackQuery(props) {

    const handleValueChange = (value, key) => {
        props.handleChange(key, value);
    }
    
    return (
        <div>
            <MultiValueSelector
                options={props.options.slackConversations}
                label="Select Channels"
                onValueChange={(value) => handleValueChange(value, 'channels')}
                initialValue={props.query ? props.query.channels : null}
            ></MultiValueSelector>
        </div>
    )

}