import { useState } from "react";
import "./searchResults.css";
import ListArticles from "../ArticleComponents/ListArticles/ListArticles";
import ResourceResults from "../ResourceComponents/ResourceResults/resourceResults";
import TrelloResults from "../TrelloComponents/TrelloResults/trelloResults";
import ZendeskResults from "../ZendeskComponents/ZendeskResults/zendeskResults";
import { HiOutlineArrowLeft } from "react-icons/hi"
import { IoIosRefresh,IoMdArrowUp  } from "react-icons/io";
import SlackResults from "../SlackComponents/SlackResults/slackResults";
import DiscourseResults from "../DiscourseComponents/DiscourseResults/discourseResults";
import DocsResults from "../DocsComponents/DocsResults/docsResults";

export default function SearchResults(props) {
    const [currentTab, setCurrentTab] = useState("articles");

    const handleBackToTop = () => {
        document.querySelector('html').scrollTop = 0;
    }
    return (
        <>
            <div className="results-options-container" style={{top: document.getElementById("nav").offsetHeight}}>
                <div>
                    <button className="back-option-button" onClick={props.onBack}><HiOutlineArrowLeft style={{fontSize: "15px", verticalAlign: "middle"}}/> Edit Query</button>
                </div>
                <div className="main-options">
                    <button className={currentTab == 'articles' ? "selected" : ""} onClick={() => setCurrentTab('articles')}>({props.searchResults?.length}) Articles</button>
                    <button className={currentTab == 'resources' ? "selected" : ""} onClick={() => setCurrentTab('resources')}>({props.resourceResults?.length}) Resources</button>
                    {props.isZendesk && <button className={currentTab == 'zendesk' ? "selected" : ""} onClick={() => setCurrentTab('zendesk')}>({props.tickets?.length}) Zendesk Tickets</button>}
                    {props.isTrello && <button className={currentTab == 'trello' ? "selected" : ""} onClick={() => setCurrentTab('trello')}>({props.trellos?.length}) Trello Tickets</button>}
                    {props.isSlack && <button className={currentTab == 'slack' ? "selected" : ""} onClick={() => setCurrentTab('slack')}>({props.slacks?.length}) Slack Messages</button>}
                    {props.isDiscourse && <button className={currentTab == 'discourse' ? "selected" : ""} onClick={() => setCurrentTab('discourse')}>({props.discourses?.length}) Discourse</button>}
                    {props.isDocs && <button className={currentTab == 'docs' ? "selected" : ""} onClick={() => setCurrentTab('docs')}>({props.docs?.length}) Applitools Docs</button>}
                </div>
                <div>
                    <button className="refresh-option-button" onClick={props.onRefresh}><IoIosRefresh style={{fontSize: "15px", verticalAlign: "middle"}}/> Refresh Results</button>
                </div>
            </div>
            
            {currentTab == 'articles' && (
                <div style={{margin: "20px"}}>
                    {props.searchResults?.length > 0 ? <ListArticles
                        articles={props.searchResults}
                    ></ListArticles> : <h2>No Articles Found</h2>}
                </div>
            )}
            {currentTab == 'resources' && (
                <div style={{margin: "20px"}}>
                    <ResourceResults
                        resources={props.resourceResults}
                    ></ResourceResults>
                </div>
            )}
            {currentTab == 'zendesk' && (
                <div style={{margin: "20px"}}>
                    {props.tickets?.length > 0 ? <ZendeskResults
                        tickets={props.tickets}
                    ></ZendeskResults> : <h2>No Tickets Found</h2>}
                </div>
            )}
            {currentTab == 'trello' && (
                <div style={{margin: "20px"}}>
                {props.trellos?.length > 0 ? <TrelloResults
                        trellos={props.trellos}
                    ></TrelloResults>: <h2>No Trellos Found</h2>}
                </div>
            )}
            {currentTab == 'slack' && (
                <div style={{margin: "20px"}}>
                {props.slacks?.length > 0 ? <SlackResults
                        slacks={props.slacks}
                    ></SlackResults>: <h2>No Slacks Found</h2>}
                </div>
            )}
            {currentTab == 'discourse' && (
                <div style={{margin: "20px"}}>
                {props.discourses?.length > 0 ? <DiscourseResults
                        discourses={props.discourses}
                        topicsMap={props.discourseTopicMap}
                    ></DiscourseResults>: <h2>No Discourse Results</h2>}
                </div>
            )}
            {currentTab == 'docs' && (
                <div style={{margin: "20px"}}>
                {props.docs?.length > 0 ? <DocsResults
                        docs={props.docs}
                    ></DocsResults>: <h2>No Docs Results</h2>}
                </div>
            )}
            <div className="sticky-back-to-top">
                <button onClick={handleBackToTop}><IoMdArrowUp style={{width: "30px", height: "30px"}}/></button>
            </div> 
        </>
    )
        
}