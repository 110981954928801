import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addResourceAsync } from "../../api/resourceApi";
import MultiValueSelector from "../../Components/MultiValueSelector/multiValueSelector";
import ResourceForm from "../../Components/ResourceComponents/ResourceForm/resourceForm";
import SingleValueSelector from "../../Components/SingleValueSelector/singleValueSelector";
import { fetchCategories } from "../../Redux/categoriesSlice";
import { setIsLoading } from "../../Redux/loadingSlice";
import { updateErrorMessage, updateInfoMessage } from "../../Redux/messageSlice";
import { clearNewResource, updateNewResource } from "../../Redux/resourcesSlice";
import { fetchTags } from "../../Redux/tagsSlice";
import "./createResource.css";

export default function CreateResource() {
    const newResource = useSelector((state) => state.resources.newResource);
    const tags = useSelector((state) => state.tags.values);
    const categories = useSelector((state) => state.categories.values);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCategories());
        dispatch(fetchTags());
    }, [])

    const handleChange = function(obj) {
        dispatch(updateNewResource(obj));
    }

    const onSave = async function() {
        try {
            let timeout = setTimeout(() => {
                dispatch(setIsLoading(true));
            }, 250);
            await addResourceAsync(newResource);
            dispatch(clearNewResource());
            clearTimeout(timeout);
            dispatch(setIsLoading(false));
            dispatch(updateInfoMessage("Resource Created"));
        } catch(e) {
            if (e.msg) {
                dispatch(updateErrorMessage(e.msg));
            } else {
                dispatch(updateErrorMessage("Error Saving Resource"));
            }
        }
        
    }
    
    return (
        <div className="create-resource-container">
            <h1>Create New Resource</h1>
            <ResourceForm
                resource={newResource}
                onValueChange={handleChange}
                showOptions={true}
                onSave={onSave}
            ></ResourceForm>
        </div>
    )

}