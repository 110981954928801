import { configureStore } from '@reduxjs/toolkit';
import categoriesReducer from '../Redux/categoriesSlice';
import tagsReducer from '../Redux/tagsSlice';
import articlesReducer from '../Redux/articlesSlice'; 
import messagesReducer from '../Redux/messageSlice';
import searchReducer from '../Redux/searchSlice';
import loadingReducer from '../Redux/loadingSlice'; 
import authReducer from '../Redux/authSlice';
import resourcesReducer from '../Redux/resourcesSlice'; 
import notificationsReducer from '../Redux/notificationsSlice';
import userReducer from '../Redux/userSlice';

export const store = configureStore({
  reducer: {
    categories: categoriesReducer,
    tags: tagsReducer,
    articles: articlesReducer,
    messages: messagesReducer,
    search: searchReducer,
    loading: loadingReducer,
    auth: authReducer,
    resources: resourcesReducer,
    notifications: notificationsReducer,
    user: userReducer
  },
});