import { checkStatusCode } from './utils';

export async function fetchCategoriesAsync() {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/getCategories', {
        credentials: "include",
    });
    const res = await req.json();
    if (res.error) throw res;
    return res;
}

export async function addCategoryAsync(category) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/saveCategory', {
        method: "POST",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(category),
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res;
}

export async function deleteCategoryAsync(id) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/deleteCategory/'+id, {
        method: "DELETE",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res;
}

export async function editCategoryAsync(category) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/editCategory', {
        method: "PUT",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(category),
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res; 
}