import React, { useState } from "react";
import GenericModal from "../../Modals/GenericModal/genericModal";
import "./manageTags.css"

function ManageTags(props) {
    const [newTagName, setNewTag] = useState("");
    const [editingIndex, setEditingIndex] = useState();
    const [editingValue, setEditingValue] = useState();
    const [modalData, setModalData] = useState({});

    const handleSave = async function() {
        const tag = {
            name: newTagName
        }
        await props.addTag(tag);
        setNewTag("");
    }

    const handleEditClick = function(name, index) {
        setEditingIndex(index);
        setEditingValue(name);
    }

    const handleSaveEdits = async function() {
        if (editingValue == props.tags[editingIndex].name) return;
        const tag = {...props.tags[editingIndex]};
        tag['name'] = editingValue; 
        await props.editTag(tag, editingIndex);
        setEditingIndex(undefined);
        setEditingValue(undefined);
    }

    const handleDelete = async function(id, index) {
        const primaryAction = {
            callback: handleDeleteConfirm,
            label: "Confirm",
            args: {
                index,
                id,
            },
            isPrimary: true,
        };

        const secondaryAction = {
            callback: handleCloseModal,
            label: "Cancel",
            args: {},
        };

        const modalPayload = {
            actions: [secondaryAction, primaryAction],
            title: "Confirm Deletion",
            body: <p>Please confirm you'd like to remove the "{props.tags[index].name}" tag. This will remove the tag from all existing articles as well.</p>,
            showModal: true,
        }
        setModalData(modalPayload);
    }

    const handleCloseModal = async function() {
        setModalData({});
    }

    const handleDeleteConfirm = async function(args) {
        await props.deleteTag(args.id, args.index);
        setModalData({});
    }

    return (
        <div className="manage-items-container" key="main">
            <input 
                className="create-new" 
                value={newTagName} 
                placeholder="Create New Tag" 
                onChange={(e) => setNewTag(e.target.value)}
                onKeyDown={(e) => e.keyCode === 13 && handleSave()} 
            />
            <button className="main-button" onClick={handleSave}>Save</button>
            <div className="options-container">
                {props.tags?.map((tag, index) => {
                    return (
                        <div key={index} className="option">
                            {index != editingIndex && <label>{tag.name}</label>}
                            {index == editingIndex && (
                                <div>
                                    <input 
                                        value={editingValue} 
                                        onChange={(e) => setEditingValue(e.target.value)}
                                        onKeyDown={(e) => e.keyCode === 13 && handleSaveEdits()}
                                    />
                                    <button onClick={handleSaveEdits}>Save Edits</button>
                                    <button onClick={() => {setEditingIndex(undefined); setEditingValue(undefined)}}>Cancel</button>
                                </div>
                            )}
                            <div>
                                {index != editingIndex && <button onClick={() => handleEditClick(tag.name, index)}>Edit</button>}
                                {index != editingIndex && <button onClick={() => handleDelete(tag._id, index)}>Delete</button>}
                            </div>
                        </div>
                    )
                })}
            </div>
            {modalData.showModal && (
                <GenericModal
                    modalData={modalData}
                ></GenericModal>
            )}
        </div>   
    );
}

export default ManageTags;