import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
    getNotificationsAsync, 
    toggleNotificationStatusAsync, 
    toggleNotificationGroupSubAsync,
    getNotificationSubscriptionsAsync,
    markAllNotificationsAsReadAsync
} from '../api/notificationsApi';
import { getArticle } from './articlesSlice';
import _ from 'lodash';
import { logout } from "./authSlice";

const initialState = {
    notifications: [],
    newNotifications: false,
    currentLatest: null,
    articleNotifsOn: false,
    notificationSubscriptions: [],
    filterByUnread: true,
}

export const getNotifications = createAsyncThunk(
    'notifications/getNotifications',
    async (data, thunkApi) => {
        try {
            const response = await getNotificationsAsync(data);
            return response;
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const getNotificationSubscriptions = createAsyncThunk(
    'notifications/getNotificationSubscriptions',
    async (data, thunkApi) => {
        try {
            const response = await getNotificationSubscriptionsAsync();
            return response;
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const toggleNotificationGroupSub = createAsyncThunk(
    'notifictions/toggleNotificationGroupSub',
    async (data, thunkApi) => {
        try {
            const response = await toggleNotificationGroupSubAsync(data);
            return {
                data,
                msg: response.msg,
            }
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const markNotifAsRead = createAsyncThunk(
    'notifictions/markNotifAsRead',
    async (data, thunkApi) => {
        try {
            const response = await toggleNotificationStatusAsync(data);
            return {
                data,
                msg: response.msg,
            }
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const markNotifAsUnread = createAsyncThunk(
    'notifictions/markNotifAsUnread',
    async (data, thunkApi) => {
        try {
            const response = await toggleNotificationStatusAsync(data);
            return {
                data,
                msg: response.msg,
            }
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const markAllNotificationsAsRead = createAsyncThunk(
    'notifictions/markAllNotificationsAsRead',
    async (data, thunkApi) => {
        try {
            const response = await markAllNotificationsAsReadAsync(data);
            return {
                data,
                msg: response.msg,
            }
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
);

const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        toggleFilterByUnread: (state, action) => {
            let curr = state.filterByUnread;
            state.filterByUnread = !curr;
        },
        setNewNotifications: (state, action) => {
            state.newNotifications = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getNotifications.fulfilled, (state, action) => {
            state.notifications = action.payload.data;
            if (action.payload.data.length > 0 && !action.payload.data[0].isRead) {
                if (!state.currentLatest || state.currentLatest != action.payload.data[0]._id) {
                    state.newNotifications = true;
                    state.currentLatest = action.payload.data[0]._id;
                }
            }
        })
        .addCase(getArticle.fulfilled, (state, action) => {
            state.articleNotifsOn = action.payload.notifsOn
        })
        .addCase(toggleNotificationGroupSub.fulfilled, (state, action) => {
            let curr = state.articleNotifsOn;
            state.articleNotifsOn = !curr;

            let index = state.notificationSubscriptions.findIndex((sub) => sub.groupId._id == action.payload.data.groupId);
            if (index >= 0) {
                curr = [...state.notificationSubscriptions];
                curr.splice(index, 1);
                state.notificationSubscriptions = curr; 
            }
        })
        .addCase(getNotificationSubscriptions.fulfilled, (state, action) => {
            state.notificationSubscriptions = action.payload.data;
        })
        .addCase(markNotifAsRead.fulfilled, (state, action) => {
            let index = action.payload.data.index;
            let curr = [...state.notifications];
            if (state.filterByUnread) {
                curr.splice(index, 1);
                state.notifications = curr;
            } else {
                let currNotif = _.cloneDeep(curr[index]);
                currNotif.isRead = true;
                curr[index] = currNotif;
                state.notifications = curr;
            }
        })
        .addCase(markAllNotificationsAsRead.fulfilled, (state, action) => {
            let temp = [...state.notifications];
            if (state.filterByUnread) {
                // if we're filtering, then just remove them all from the view
                temp = [];
            } else {
                // if we're not filtering, then mark all as read
                for (let i = 0; i < temp.length; i++) {
                    let currentNotif = temp[i];
                    if (!currentNotif.isRead) {
                        currentNotif.isRead = true;
                    }
                }
            }
            state.notifications = temp;
        })
        .addCase(markNotifAsUnread.fulfilled, (state, action) => {
            let index = action.payload.data.index;
            let curr = [...state.notifications];
            let currNotif = _.cloneDeep(curr[index]);
            currNotif.isRead = false;
            curr[index] = currNotif;
            state.notifications = curr;
        })
        .addCase(logout.fulfilled, (state, action) => {
            state.notifications = [];
            state.newNotifications = false;
            state.currentLatest = null;
            state.articleNotifsOn = false;
            state.notificationSubscriptions = [];
        })
    }
})

export const { 
    toggleFilterByUnread,
    setNewNotifications
} = notificationSlice.actions
export default notificationSlice.reducer 