export default function DiscourseResults(props) {

    return (
        <>
            {props.discourses?.map((post, index) => {
                return (
                    <a href={`https://discourse.applitools.com/t/${post.topic_id}`} draggable="false" target="_blank" rel="nonreferrer" className="link-container" key={index}>
                        <div className="search-result-container">
                            <div className="header">
                                <div>
                                    <h2>{props.topicsMap[post.topic_id].title}</h2>
                                    <p className="sub-header">{`https://discourse.applitools.com/t/${post.topic_id}`}</p>
                                </div>
                                <span className="category">{post.name}</span>
                            </div>
                            <div className="tag-container">
                                {props.topicsMap[post.topic_id].tags?.map((tag, index) => <span key={index} className="tag">{tag}</span>)}
                            </div>
                            <div className="description">
                                {post.blurb}
                            </div>
                        </div>
                    </a>
                )
            })}
        </>
    )
}