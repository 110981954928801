import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchEnhancedQueryOptionsAsync, generalSearchAsync } from '../api/searchApi';
import { deleteResource, editResource } from './resourcesSlice';
import { logout } from "./authSlice";

/* 
searchQuery: {
    text,
    category, 
    tags,
    isZendesk,
    isTrello,
    zendeskQuery: {
        sdkTag,
        gridProviderTag,
        TopicTag,
    },
    trelloQuery: {
        boards: String
    }
}
*/

const initialState = {
    articleResults: [],
    zendeskResults: [],
    trelloResults: [],
    slackResults: [],
    resourceResults: [],
    discourseResults: [],
    discourseTopicMap: {},
    docsResults: [],
    showResults: false,
    isZendesk: true,
    isTrello: true,
    isSlack: true,
    isDiscourse: true,
    isDocs: true,
    searchQuery: {text: ""},
    zendeskQuery: {},
    trelloQuery: {},
    slackQuery: {},
    enhancedQueryOptions: {},
    didFetchOptions: false,
}

export const generalSearch = createAsyncThunk(
    'search/generalSearch',
    async (data, thunkAPI) => {
        try {
            const { searchQuery, isZendesk, isTrello, isSlack, isDiscourse, isDocs, zendeskQuery, trelloQuery, slackQuery } = thunkAPI.getState()['search'];
            const body = {
                searchQuery,
                isZendesk,
                isTrello,
                isSlack,
                isDiscourse,
                isDocs,
                zendeskQuery,
                trelloQuery,
                slackQuery,
                slackConfigured: data.slackConfigured,
            };
            const response = await generalSearchAsync(body);
            return response.data;
        } catch(e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
)

export const fetchEnhancedQueryOptions = createAsyncThunk(
    'search/fetchZDOptions',
    async (data, thunkAPI) => {
        try {   
            const response = await fetchEnhancedQueryOptionsAsync();
            return response.data; 
        } catch(e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
)

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        updateSearchQuery: (state, action) => {
            state.searchQuery = action.payload;
        },
        clearSearchQuery: (state) => {
            state.searchQuery = {text: ""};
            state.isTrello = false;
            state.isZendesk = false;
            state.isDiscourse = false;
            state.isSlack = false;
            state.isDocs = false;
            state.zendeskQuery = {};
            state.trelloQuery = {};
        },
        updateZendeskQuery: (state, action) => {
            state.zendeskQuery = action.payload;
        },
        updateTrelloQuery: (state, action) => {
            state.trelloQuery = action.payload;
        },
        updateSlackQuery: (state, action) => {
            state.slackQuery = action.payload;
        },
        toggleShowResults: (state, action) => {
            state.showResults = action.payload;
        },
        toggleZendesk: (state, action) => {
            let curr = state.isZendesk;
            state.isZendesk = !curr;
        },
        toggleTrello: (state, action) => {
            let curr = state.isTrello;
            state.isTrello = !curr;
        },
        toggleSlack: (state, action) => {
            let curr = state.isSlack;
            state.isSlack = !curr;
        },
        toggleDiscourse: (state, action) => {
            let curr = state.isDiscourse;
            state.isDiscourse = !curr;
        },
        toggleDocs: (state, action) => {
            let curr = state.isDocs;
            state.isDocs = !curr;
        },
        removeArticleFromResults: (state, action) => {
            // will this be inefficient at scale and when pagination is involved? 
            let id = action.payload;
            let currArticles = [...state.articleResults];
            let index = currArticles.indexOf((article) => article._id == id);
            if (index > -1) {
                currArticles.splice(index, 1);
                state.articleResults = currArticles;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(generalSearch.fulfilled, (state, action) => {
                state.articleResults = action.payload.articles;
                state.resourceResults = action.payload.resources;
                if (state.isZendesk) {
                    state.zendeskResults = action.payload.tickets;
                } else {
                    state.zendeskResults = [];
                }
                if (state.isTrello) {
                    state.trelloResults = action.payload.trellos;
                } else {
                    state.trelloResults = [];
                }
                if (state.isSlack) {
                    state.slackResults = action.payload.slacks;
                } else {
                    state.slackResults = []; 
                }

                if (state.isDiscourse && action.payload.discourses.posts) {
                    let baseMap = {};
                    action.payload.discourses?.topics?.map((topic) => {
                        baseMap[topic.id] = topic;
                    })
                    state.discourseTopicMap = baseMap;
                    delete action.payload.discourses?.topics;
                    console.log(action.payload);
                    state.discourseResults = action.payload.discourses.posts;
                } else {
                    state.discourseResults = [];
                    state.discourseTopicMap = {};
                }

                console.log(state.discourseResults);

                if (state.isDocs) {
                    state.docsResults = action.payload.docs
                } else {
                    state.docsResults = [];
                }
            })
            .addCase(fetchEnhancedQueryOptions.fulfilled, (state, action) => {
                state.enhancedQueryOptions = action.payload;
                state.didFetchOptions = true;
            })
            .addCase(editResource.fulfilled, (state, action) => {
                let data = action.payload.data;
                let resourceIndex = state.resourceResults.findIndex((resource) => resource._id == data._id);
                if (resourceIndex > -1) {
                    let temp = [...state.resourceResults];
                    temp[resourceIndex] = data;
                    state.resourceResults = temp;
                }
            })
            .addCase(deleteResource.fulfilled, (state, action) => {
                let id = action.payload.id;
                let resourceIndex = state.resourceResults.findIndex((resource) => resource._id == id);
                if (resourceIndex > -1) {
                    let temp = [...state.resourceResults];
                    temp.splice(resourceIndex, 1);
                    state.resourceResults = temp; 
                }
            })
            .addCase(logout.fulfilled, (state, action) => {
                state.articleResults = [];
                state.zendeskResults = [];
                state.trelloResults = [];
                state.slackResults = [];
                state.resourceResults = [];
                state.discourseResults = [];
                state.discourseTopicMap = {};
                state.docsResults = [];
                state.showResults = false;
                state.searchQuery = {text: ""};
                state.zendeskQuery = {};
                state.trelloQuery = {};
                state.enhancedQueryOptions = {};
                state.didFetchOptions = false;
            })
    },
})

export const {
    updateSearchQuery, 
    clearSearchQuery, 
    toggleShowResults, 
    toggleZendesk, 
    toggleTrello, 
    toggleSlack,
    toggleDiscourse,
    toggleDocs,
    updateZendeskQuery, 
    updateTrelloQuery, 
    updateSlackQuery,
    removeArticleFromResults 
} = searchSlice.actions;
export default searchSlice.reducer;