import { checkStatusCode } from "./utils";

export async function addArticleAsync(article) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/saveArticle', {
        method: "POST",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(article),
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res;
}

export async function editArticleAsync(article) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/editArticle', {
        method: "PUT",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(article),
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res;
}

export async function deleteArticleAsync(id) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/deleteArticle/' + id, {
        method: "DELETE",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res; 
}

export async function getArticleAsync(id) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/getArticle/' + id, {
        credentials: "include"
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res;
}

export async function getMostRecentArticlesAsync() {
    const ARTICLES_TO_RETRIEVE = 10;
    const req = await fetch(process.env.REACT_APP_API_URL + `/api/getMostRecentArticles?numArticles=${ARTICLES_TO_RETRIEVE}`, {
        credentials: "include"
    });
    const res = await req.json();
    if (res.error) throw res;
    return res.data;
}

export async function saveCommentAsync(comment) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/comment', {
        method: "POST",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(comment),
    })
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res; 
}

export async function saveZendeskTicketAsync(data) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/article/zendesk', {
        method: "POST",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res; 
}

export async function deleteZendeskTicketAsync(data) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/article/zendesk/delete', {
        method: "POST",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    })
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res; 
}

export async function saveTrelloTicketAsync(data) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/article/trello', {
        method: "POST",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    })
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res; 
}

export async function deleteTrelloTicketAsync(data) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/article/trello/delete', {
        method: "POST",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    })
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res; 
}

export async function deleteCommentAsync(id) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/comment/' + id, {
        method: "DELETE",
        credentials: "include",
    })
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res; 
}

export async function saveReplyAsync(data) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/comment/reply', {
        method: "PUT",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    })
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res; 
}

export async function deleteReplyAsync(id) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/comment/reply/' + id, {
        method: "DELETE",
        credentials: "include",
    })
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res; 
}

export async function editCommentAsync(data) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/comment', {
        method: "PUT",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    })
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res; 
}