import ReactMarkdown from 'react-markdown'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import gfm from 'remark-gfm'
import {dracula} from 'react-syntax-highlighter/dist/esm/styles/prism'

// props: markdown, 
function MarkdownViewer(props) {

    return (
        <ReactMarkdown
            children={props.markdown}
            remarkPlugins={[gfm]}
            components={{
                code({node, inline, className, children, ...props}) {
                    const match = /language-(\w+)/.exec(className || '')
                    return !inline && match ? (
                    <SyntaxHighlighter
                        children={String(children).replace(/\n$/, '')}
                        style={dracula}
                        language={match[1]}
                        PreTag="div"
                        {...props}
                    />
                    ) : (
                    <code className={className} {...props}>
                        {children}
                    </code>
                    )
                },
                h1({node, children, ...props}) {
                    let id; 
                    if (!children || children.length == 0) { 
                        return (
                            <h1>{children}</h1>
                        )
                    } else if (typeof children[0] == 'string') {
                        id = children ? children[0].toLowerCase().split(" ").join("-") : null;
                        return (
                            <h1 id={id ? id : null} className="article-anchor">{children ? children[0] : null}</h1>
                        )
                    } else if (children[0].props) {
                        return (
                            <h1>{children}</h1>
                        )
                    }
                },
                h2({node, children, ...props}) {
                    let id; 
                    if (!children || children.length == 0) {
                        return (
                            <h2>{children}</h2>
                        )
                    } else if (typeof children[0] == 'string') {
                        id = children ? children[0].toLowerCase().split(" ").join("-") : null;
                        return (
                            <h2 id={id ? id : null} className="article-anchor">{children ? children[0] : null}</h2>
                        )
                    } else if (children[0].props) {
                        return (
                            <h2>{children}</h2>
                        )
                    }
                },
                h3({node, children, ...props}) {
                    let id; 
                    if (!children || children.length == 0) {
                        return (
                            <h3>{children}</h3>
                        )
                    } else if (typeof children[0] == 'string') {
                        id = children ? children[0].toLowerCase().split(" ").join("-") : null;
                        return (
                            <h3 id={id ? id : null} className="article-anchor">{children ? children[0] : null}</h3>
                        )
                    } else if (children[0].props) {
                        return (
                            <h3>{children}</h3>
                        )
                    }
                },
                h4({node, children, ...props}) {
                    let id; 
                    if (!children || children.length == 0) {
                        return (
                            <h4>{children}</h4>
                        )
                    } else if (typeof children[0] == 'string') {
                        id = children ? children[0].toLowerCase().split(" ").join("-") : null;
                        return (
                            <h4 id={id ? id : null} className="article-anchor">{children ? children[0] : null}</h4>
                        )
                    } else if (children[0].props) {
                        return (
                            <h4>{children}</h4>
                        )
                    }
                },
                h5({node, children, ...props}) {
                    let id; 
                    if (!children || children.length == 0) {
                        return (
                            <h5>{children}</h5>
                        )
                    } else if (typeof children[0] == 'string') {
                        id = children ? children[0].toLowerCase().split(" ").join("-") : null;
                        return (
                            <h5 id={id ? id : null} className="article-anchor">{children ? children[0] : null}</h5>
                        )
                    } else if (children[0].props) {
                        return (
                            <h5>{children}</h5>
                        )
                    }
                },
                h6({node, children, ...props}) {
                    let id; 
                    if (!children || children.length == 0) {
                        return (
                            <h6>{children}</h6>
                        )
                    } else if (typeof children[0] == 'string') {
                        id = children ? children[0].toLowerCase().split(" ").join("-") : null;
                        return (
                            <h6 id={id ? id : null} className="article-anchor">{children ? children[0] : null}</h6>
                        )
                    } else if (children[0].props) {
                        return (
                            <h6>{children}</h6>
                        )
                    }
                }
                
            }}
        ></ReactMarkdown>
    )
}
export default MarkdownViewer;