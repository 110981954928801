import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom"
import { verifySlackAsync } from "../../api/authApi";
import { updateSlackConfigured } from "../../Redux/authSlice";
import { updateErrorMessage, updateInfoMessage } from "../../Redux/messageSlice";

export default function VerifySlack() {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const nav = useNavigate();
    useEffect(async () => {
        const slackCode = searchParams.get("code");
        try {
            const body = {
                code: slackCode
            }
            let res = await verifySlackAsync(body);
            if (!res.error) {
                await dispatch(updateSlackConfigured(true));
                dispatch(updateInfoMessage(res.msg));
            }
        } catch(e) {
            console.log(e);
            if (e.msg) {
                dispatch(updateErrorMessage(e.msg));
            } else {
                dispatch(updateErrorMessage("Unexpected Error Syncing Slack, Contact Admins"));
            }
        }
        
        nav('/profile');
        
    }, [])
    return (
        <div>Verifying Slack...</div>
    )
}