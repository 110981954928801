import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./search.css";
import "../Admin/admin.css";
import {
    fetchCategories,
} from '../../Redux/categoriesSlice';
import { 
    fetchTags,
} from "../../Redux/tagsSlice";
import {
    generalSearch,
    updateSearchQuery,
    clearSearchQuery,
    toggleShowResults,
    toggleZendesk,
    toggleTrello,
    toggleSlack,
    toggleDocs,
    fetchEnhancedQueryOptions,
    updateZendeskQuery,
    updateTrelloQuery,
    toggleDiscourse,
    updateSlackQuery,
} from '../../Redux/searchSlice';
import SearchResults from "../../Components/SearchResults/searchResults";
import SingleValueSelector from "../../Components/SingleValueSelector/singleValueSelector";
import MultiValueSelector from "../../Components/MultiValueSelector/multiValueSelector";
import ZendeskQuery from "../../Components/ZendeskComponents/ZendeskQuery/zendeskQuery";
import { setIsLoading } from "../../Redux/loadingSlice";
import { useLocation } from "react-router-dom";
import TrelloQuery from "../../Components/TrelloComponents/TrelloQuery/trelloQuery";
import MyCheckbox from "../../Components/MyCheckbox/myCheckbox";
import SlackQuery from "../../Components/SlackComponents/SlackQuery/slackQuery";

function Search() {
    const [currentTab, setCurrentTab] = useState('main');
    const isAuth = useSelector((state) => state.auth.isLoggedIn);

    const showResults = useSelector((state) => state.search.showResults);
    const isZendesk = useSelector((state) => state.search.isZendesk);
    const isTrello = useSelector((state) => state.search.isTrello);
    const isSlack = useSelector((state) => state.search.isSlack);
    const slackConfigured = useSelector((state) => state.auth.slackConfigured);
    const isDiscourse = useSelector((state) => state.search.isDiscourse);
    const isDocs = useSelector((state) => state.search.isDocs);

    const enhancedQueryOptions = useSelector((state) => state.search.enhancedQueryOptions);
    const didFetchOptions = useSelector((state) => state.search.didFetchOptions);

    const articleResults = useSelector((state) => state.search.articleResults);
    const resourceResults = useSelector((state) => state.search.resourceResults);
    const zendeskResults = useSelector((state) => state.search.zendeskResults);
    const trelloResults = useSelector((state) => state.search.trelloResults);
    const slackResults = useSelector((state) => state.search.slackResults);
    const discourseResults = useSelector((state) => state.search.discourseResults);
    const discourseTopicMap = useSelector((state) => state.search.discourseTopicMap);
    const docsResults = useSelector((state) => state.search.docsResults);

    const searchQuery = useSelector((state) => state.search.searchQuery);
    const zendeskQuery = useSelector((state) => state.search.zendeskQuery);
    const trelloQuery = useSelector((state) => state.search.trelloQuery);
    const slackQuery = useSelector((state) => state.search.slackQuery);

    const categories = useSelector((state) => state.categories.values);
    const tags = useSelector((state) => state.tags.values);
    const dispatch = useDispatch();
    const {state} = useLocation();

    useEffect(() => {
        const initializeSearch = async () => {
            dispatch(fetchCategories());
            dispatch(fetchTags());
            if (!didFetchOptions && isAuth) {
                dispatch(fetchEnhancedQueryOptions());
            }
            if(state?.fromHome) {
                handleToggleShowResults(true);
                if (searchQuery.text) {
                    handleTextChange(searchQuery.text);
                } else if (searchQuery.category) {
                    handleCategoryChange(searchQuery.category);
                }
                await handleSearchArticles();
            }
        }
        initializeSearch();
    }, [])

    const handleCategoryChange = (value) => {
        let q = {...searchQuery};
        q['category'] = value;
        dispatch(updateSearchQuery(q));
    }

    const handleTagChange = (value) => {
        let q = {...searchQuery};
        q['tags'] = value;
        dispatch(updateSearchQuery(q));
    }

    const handleTextChange = (value) => {
        let q = {...searchQuery};
        q['text'] = value;
        dispatch(updateSearchQuery(q));
    }

    const handleSearchArticles = async () => {
        let timeout = setTimeout(() => {
            dispatch(setIsLoading(true));
        }, 250);
        await dispatch(generalSearch({slackConfigured}));
        clearTimeout(timeout);
        dispatch(setIsLoading(false));
        dispatch(toggleShowResults(true));
    }

    const handleToggleShowResults = (value) => {
        dispatch(toggleShowResults(value));
    }

    const handleRefresh = async () => {
        let timeout = setTimeout(() => {
            dispatch(setIsLoading(true));
        }, 250);
        clearTimeout(timeout);
        await dispatch(generalSearch({slackConfigured}));
        dispatch(setIsLoading(false));
    }

    const handleClear = () => {
        dispatch(clearSearchQuery());
        setCurrentTab('main');
    }

    const handleZendeskQueryChange = (key, value) => {
        let q = {...zendeskQuery};
        q[key] = value;
        dispatch(updateZendeskQuery(q));
    }
    
    const handleTrelloQueryChange = (key, value) => {
        let q = {...trelloQuery};
        q[key] = value;
        dispatch(updateTrelloQuery(q));
    }

    const handleSlackQueryChange = (key, value) => {
        let q = {...slackQuery};
        q[key] = value;
        console.log(q);
        dispatch(updateSlackQuery(q));
    }

    const handleExternalToolChange = (tool) => {
        if (tool == "zd") {
            if (currentTab == "zd") setCurrentTab("main");
            dispatch(toggleZendesk());
        } else if (tool == "tr") {
            if (currentTab == "trello") setCurrentTab("main");
            dispatch(toggleTrello());
        } else if (tool == "sl") {
            if (currentTab == 'slack') setCurrentTab("main");
            dispatch(toggleSlack());
        } else if (tool == "disc") {
            dispatch(toggleDiscourse());
        } else if (tool == 'docs') {
            dispatch(toggleDocs());
        }
    }

    return (
        <div className="search-container">
        {!showResults && (
            <div className="query fadeIn">
                <div className="search-query-header">
                    <h2>Search Knowledge Base</h2>
                    <input 
                        placeholder="Search Query" 
                        value={searchQuery.text} 
                        onChange={(e) => handleTextChange(e.target.value)} 
                        onKeyDown={(e) => e.keyCode === 13 && handleSearchArticles()}
                    />
                </div>
                <div className="search-query-entry search-tool-selection">
                    {isAuth && [
                        <MyCheckbox
                            label="Zendesk"
                            key="zd"
                            isChecked={isZendesk}
                            onClick={() => {
                                handleExternalToolChange("zd");
                            }}
                        ></MyCheckbox>,
                        <MyCheckbox
                            key="trello"
                            isChecked={isTrello}
                            onClick={() => handleExternalToolChange("tr")}
                            label="Trello"
                        ></MyCheckbox>,
                        <React.Fragment  key="slack">
                            {slackConfigured && (
                                <MyCheckbox
                                    isChecked={isSlack}
                                    onClick={() => handleExternalToolChange("sl")}
                                    label="Slack"
                                ></MyCheckbox>
                            )}  
                        </React.Fragment>,
                        <MyCheckbox
                            key="disc"
                            isChecked={isDiscourse}
                            onClick={() => handleExternalToolChange("disc")}
                            label="Discourse"
                        ></MyCheckbox>,
                        <MyCheckbox
                            key="docs"
                            isChecked={isDocs}
                            onClick={() => handleExternalToolChange("docs")}
                            label="Docs"
                        ></MyCheckbox>,
                    ]} 
                </div>

                {(isZendesk || isTrello || isSlack) && !showResults && (
                    <div className="search-subnav">
                        {(isZendesk || isTrello || isSlack) && <button className={currentTab == 'main' ? "selected-search-subnav" : ""} onClick={() => setCurrentTab('main')}>Main Query</button>}
                        {isZendesk && isAuth && <button className={currentTab == 'zd' ? "selected-search-subnav" : ""} onClick={() => setCurrentTab('zd')}>Zendesk Query</button>}
                        {isTrello && isAuth && <button className={currentTab == 'trello' ? "selected-search-subnav" : ""}onClick={() => setCurrentTab('trello')}>Trello Query</button>}
                        {isSlack && isAuth && slackConfigured && <button className={currentTab == 'slack' ? "selected-search-subnav" : ""} onClick={() => setCurrentTab('slack')}>Slack Query</button>}
                    </div>
                )}
               {currentTab == 'main' && (
                    <div className="search-query-body">
                        <div className="search-query-entry">
                            <SingleValueSelector
                                label="Category"
                                options={categories}
                                displayField="name"
                                onValueChange={handleCategoryChange}
                                initialValue={searchQuery.category}
                            ></SingleValueSelector>
                        </div>
                        <div className="search-query-entry">
                            <MultiValueSelector
                                options={tags}
                                label="Tags"
                                displayField="name"
                                onValueChange={handleTagChange}
                                initialValue={searchQuery.tags}
                                hideValues={true}
                            ></MultiValueSelector>
                        </div>
                    </div>
               )}
               {currentTab == 'zd' && isAuth &&  (
                   <div className="search-query-body">
                        <ZendeskQuery
                            options={enhancedQueryOptions}
                            query={zendeskQuery}
                            handleChange={handleZendeskQueryChange}
                        ></ZendeskQuery>
                    </div>
                )}
                {currentTab == 'trello' && isAuth &&  (
                    <div className="search-query-body">
                        <TrelloQuery
                            options={enhancedQueryOptions}
                            query={trelloQuery}
                            handleChange={handleTrelloQueryChange}
                        ></TrelloQuery>
                    </div>
                )}
                {currentTab == 'slack' && isAuth && slackConfigured && (
                    <div className="search-query-body">
                        {console.log(slackQuery)}
                        <SlackQuery
                            options={enhancedQueryOptions}
                            query={slackQuery}
                            handleChange={handleSlackQueryChange}
                        ></SlackQuery>
                    </div>
                )}
                <div style={{textAlign: "center"}}>
                    <button className="secondary-button" onClick={handleClear}>Clear</button>
                    <button className="main-button" onClick={() => handleSearchArticles()} style={{width: "40%", maxWidth: "300px"}}>Search</button>
                </div>
            </div>
        )}
        {showResults && (
            <div className="fadeIn">
                <SearchResults
                    searchResults={articleResults}
                    resourceResults={resourceResults}
                    tickets={zendeskResults}
                    trellos={trelloResults}
                    slacks={slackResults}
                    discourses={discourseResults}
                    discourseTopicMap={discourseTopicMap}
                    docs={docsResults}
                    isZendesk={isZendesk}
                    isTrello={isTrello}
                    isSlack={isSlack && slackConfigured}
                    isDiscourse={isDiscourse}
                    isDocs={isDocs}
                    onBack={() => handleToggleShowResults(false)}
                    onRefresh={() => handleRefresh()}
                ></SearchResults>
            </div>
        )}
        </div>
    )
}

export default Search;