import { useEffect } from "react";
import { getMostRecentArticles } from "../../Redux/articlesSlice";
import { getFavoriteArticles } from "../../Redux/userSlice";
import { updateSearchQuery } from '../../Redux/searchSlice';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "./Home.css";
import ListArticles from "../../Components/ArticleComponents/ListArticles/ListArticles";
import { FiExternalLink } from "react-icons/fi" 
import { fetchCategories } from "../../Redux/categoriesSlice";

function Home() {
    const recentArticles = useSelector((state) => state.articles.recentArticles);
    const articleFavorites = useSelector((state) => state.user.articleFavorites);
    const searchQuery = useSelector((state) => state.search.searchQuery);
    const categories = useSelector((state) => state.categories.values);
    const userId = useSelector((state) => state.auth.userId);
    
    const nav = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        async function setInitialArticlesState() {
            await handleGetRecentArticles();
            if (userId) await handleGetFavoriteArticles();
            await dispatch(fetchCategories());
        }
        setInitialArticlesState();
    }, []);

    const handleGetRecentArticles = async () => {
        await dispatch(getMostRecentArticles());
    }

    const handleGetFavoriteArticles = async () => {
        await dispatch(getFavoriteArticles({_id: userId}));
    }

    const handleTextChange = (value) => {
        let q = {...searchQuery};
        q['text'] = value;
        dispatch(updateSearchQuery(q));
    }

    const handleNavToSearch = async (searchQuery) => {
        await dispatch(updateSearchQuery(searchQuery));
        nav("/search",
            {state: {
                fromHome: true,
                searchQuery: searchQuery.text
            }}
        );
    }

    return (
        <div className="home-page-content">
            <div className="home-banner row"> 
                <div className="home-banner-content">Applitools Internal Knowledge Base</div>
                <div className="home-search-input">
                    <input 
                        className="search" 
                        placeholder= {searchQuery.text || "Try searching for a topic"}
                        value={searchQuery.text} 
                        onChange={(e) => handleTextChange(e.target.value)} 
                        onKeyDown={(e) => e.keyCode === 13 && handleNavToSearch(searchQuery)}
                    />
                    <Link 
                        to={"/search"}
                        state={{
                            fromHome: true,
                            searchQuery: searchQuery.text
                        }}
                    > 
                        <button className="submit" type="submit"> Search </button> 
                    </Link>
                    <div className="advanced-search-link">                        
                        <Link to={"/search"}>Advanced Search</Link>  <FiExternalLink style={{fontSize: "15px", verticalAlign: "middle"}}></FiExternalLink>
                    </div>
                </div>
                
            </div>
            {categories?.length > 0 && (
                <div className="quick-categories">
                    <h2 className="section-title">Articles By Category</h2>
                    {categories.map((category) => {
                        return (<div className="quick-link-category" key={category._id} onClick={() => handleNavToSearch({text: "", category})}>
                            <p >{category.name}</p>
                        </div>)
                    })}
                    <div className="quick-link-category main-category" onClick={() => handleNavToSearch({text: ""})}>
                        <p >View All Articles</p>
                    </div>
                </div>
            )}
            {articleFavorites?.length > 0 && (
                <div className="favorite-articles">
                    <div><h2 className="section-title">Favorite Articles</h2></div>
                    <div className="favorite-articles-list">{<ListArticles articles={articleFavorites} isGrid={true} />}</div>
                </div>
            )}
            <div className="recent-articles">
                <div><h2 className="section-title">Recently Added Articles</h2></div>
                <div className="recent-articles-list">{<ListArticles articles={recentArticles} isGrid={true} />}</div>
            </div>
        </div>
    );
}

export default Home;