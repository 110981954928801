import "../../ArticleComponents/ListArticles/ListArticles.css";
import Markdown from 'markdown-to-jsx';
import {dateFormatter} from "../../../utils/dateUtils.js";

const VISIBLE_DESCRIPTION_LENGTH = 700;

export default function TrelloResults(props) {

    const getFormattedDescription = (trello) => {
        let desc = `${trello.description.substring(0,VISIBLE_DESCRIPTION_LENGTH)}`;
        if (desc.length == VISIBLE_DESCRIPTION_LENGTH) desc += "...";
        return desc
    }

    return (
        <>
            {props.trellos?.map((trello, index) => {
                return (
                    
                    <a href={trello.url} draggable="false" target="_blank" rel="nonreferrer" className="link-container" key={index}>
                        <div className="search-result-container" key={index}>
                            <div className="header">
                                <div>
                                    <h2>{trello.title}</h2>
                                    <p className="sub-header">Last updated {dateFormatter(trello.lastEdited)} - {trello.url}</p>
                                </div>
                                <span className="category">{trello.board}</span>
                            </div>
                            <div style={{textAlign: "left", margin: "20px 0px"}}>
                                <span className="tag">{trello.list}</span>
                            </div>
                            {trello.description && (<div className="description">
                                <div>
                                    <div>
                                        <Markdown>{getFormattedDescription(trello)}</Markdown>
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    </a>
                    // {hoverIndex === trello.id ? <TrelloPreviewModal card={trello} /> : ""}
                )
            })}
        </>
    )
}