import { dateFormatter } from "../../../utils/dateUtils";
import "../notification-styles.css";
import { useNavigate } from "react-router-dom";
import MyCheckbox from "../../MyCheckbox/myCheckbox";
import { useDispatch, useSelector } from "react-redux"
import { markNotifAsRead, toggleFilterByUnread, markNotifAsUnread, setNewNotifications, markAllNotificationsAsRead } from "../../../Redux/notificationsSlice";
import { useEffect, useRef } from "react";
import { setIsLoading } from "../../../Redux/loadingSlice";

export default function NotificationViewer(props) {

    const nav = useNavigate();
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.auth.userId);
    const {notifications, filterByUnread} = props;
    const ref = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                props.onClickOutside && props.onClickOutside();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        }
    }, [props.onClickOutside])

    const handleView = (url, notification, index, isExternal = false) => {
        // mark notification as read
        if (!notification.isRead) {
            console.log("marking as read")
            handleMarkAsRead(notification, index);
        }
        props.onView();
        if (isExternal) {
            window.open(url, "_blank");
        } else {
            nav(url);
        }
    }

    const handleCheckToggle = async () => {
        const timeout = setTimeout(() => {
            dispatch(setIsLoading(true));
        }, 250)
        
        await props.fetchNotifications(!filterByUnread);
        await dispatch(toggleFilterByUnread());
        dispatch(setNewNotifications(false))
        clearTimeout(timeout);
        dispatch(setIsLoading(false));
    }

    const handleMarkAsRead = (notification, index) => {
        const body = {
            notifId: notification._id,
            currentValue: notification.isRead,
            index
        }
        dispatch(markNotifAsRead(body));
    }

    const handleMarkAsUnread = (notification, index) => {
        const body = {
            notifId: notification._id,
            currentValue: notification.isRead,
            index
        }
        
        dispatch(markNotifAsUnread(body));
    }

    const markAllNotifsAsRead = () => {
        const body = {
            userId: userId,
        }
        dispatch(markAllNotificationsAsRead(body));
    }

    return (
        <div className="notification-viewer" ref={ref}>
            <div className="notification-viewer-header" key="header">
                <MyCheckbox
                    isChecked={filterByUnread}
                    onClick={handleCheckToggle}
                    label="Filter By Unread"
                ></MyCheckbox>
                <button className='dismiss-all-button' onClick={
                    () => markAllNotifsAsRead()}
                > Dismiss All </button>
            </div>
            <div className="notification-viewer-container" key="notifs">
                {notifications?.map((notification, index) => {
                    if (notification.notification.notifType == "article_commented") {
                        return (
                            <div className={notification.isRead ? "notification-container" : "notification-container unread-notification-container"} key={notification._id}>
                                <div className={notification.isRead ? "notification-header read-header" : "notification-header unread-header"}>
                                    <h3>
                                        {notification.isRead && <span className="read-notification-indicator"></span>}
                                        {!notification.isRead && <span className="unread-notification-indicator"></span>}
                                        New Comment
                                    </h3>
                                    <p>{dateFormatter(notification.dateCreated)}</p>
                                </div>
                                <p style={{margin: "30px 20px"}}
                                    dangerouslySetInnerHTML={{ __html: notification.notification.message}}
                                />
                                <div>
                                    {!notification.isRead && (
                                        <button onClick={() => handleMarkAsRead(notification, index)} className="secondary-button">Dismiss</button>
                                    )}
                                    {notification.isRead && (
                                        <button onClick={() => handleMarkAsUnread(notification, index)} className="secondary-button">Mark Unread</button>
                                    )}
                                    <button
                                        onClick={() => handleView(`/view-article/${notification.notification.groupId}#article-extras`, notification, index)}
                                        className="main-button" 
                                        style={{marginBottom:"20px"}}
                                    >View Comment</button>
                                </div>
                            </div>
                        )
                    } else if (notification.notification.notifType == "article_created") {
                        return (
                            <div className={notification.isRead ? "notification-container" : "notification-container unread-notification-container"} key={notification._id}>
                                <div className={notification.isRead ? "notification-header read-header" : "notification-header unread-header"}>
                                    <h3>
                                        {notification.isRead && <span className="read-notification-indicator"></span>}
                                        {!notification.isRead && <span className="unread-notification-indicator"></span>}
                                        New Article
                                    </h3>
                                    <p>{dateFormatter(notification.dateCreated)}</p>
                                </div>
                                <p style={{margin: "30px 20px"}}
                                    dangerouslySetInnerHTML={{ __html: notification.notification.message}}
                                />
                                <div>
                                    {!notification.isRead && (
                                        <button onClick={() => handleMarkAsRead(notification, index)} className="secondary-button">Dismiss</button>
                                    )}
                                    {notification.isRead && (
                                        <button onClick={() => handleMarkAsUnread(notification, index)} className="secondary-button">Mark Unread</button>
                                    )}
                                    <button 
                                        onClick={() => handleView(`/view-article/${notification.notification.metadata.articleId}`, notification, index)}
                                        className="main-button" 
                                        style={{marginBottom:"20px"}}
                                    >View Article</button>
                                </div>
                            </div>
                        )
                    } else if (notification.notification.notifType == "resource_created") {
                        return (
                            <div className={notification.isRead ? "notification-container" : "notification-container unread-notification-container"} key={notification._id}>
                                <div className={notification.isRead ? "notification-header read-header" : "notification-header unread-header"}>
                                    <h3>
                                        {notification.isRead && <span className="read-notification-indicator"></span>}
                                        {!notification.isRead && <span className="unread-notification-indicator"></span>}
                                        New Resource
                                    </h3>
                                    <p>{dateFormatter(notification.dateCreated)}</p>
                                </div>
                                <p style={{margin: "30px 20px"}}
                                    dangerouslySetInnerHTML={{ __html: notification.notification.message}}
                                />
                                <div>
                                    {!notification.isRead && (
                                        <button onClick={() => handleMarkAsRead(notification, index)} className="secondary-button">Dismiss</button>
                                    )}
                                    {notification.isRead && (
                                        <button onClick={() => handleMarkAsUnread(notification, index)} className="secondary-button">Mark Unread</button>
                                    )}
                                    <button 
                                        onClick={() => handleView(notification.notification.metadata.resourceUrl, notification, index, true)}
                                        className="main-button" 
                                        style={{marginBottom:"20px"}}
                                    >View Resource</button>
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
        </div>
    )
}