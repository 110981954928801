import EditArticle from "../../Components/ArticleComponents/EditArticle/editArticle";
import { useSelector, useDispatch } from "react-redux";
import {
    addArticle,
    updateNewArticle,
    addPhotoToNewArticle
} from '../../Redux/articlesSlice';
import {
    fetchCategories,
} from '../../Redux/categoriesSlice';
import { 
    fetchTags,
} from "../../Redux/tagsSlice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateErrorMessage } from "../../Redux/messageSlice";
import { setIsLoading } from "../../Redux/loadingSlice";
import { uploadFiles, uploadPhoto } from "../../api/fileApi";
import { handleForbiddenStatus } from "../../Redux/authSlice";


function CreateArticle() {
    const dispatch = useDispatch();
    const newArticle = useSelector((state) => state.articles.newArticle);
    const categories = useSelector((state) => state.categories.values);
    const tags = useSelector((state) => state.tags.values);
    const [files, setFiles] = useState([]);
    const nav = useNavigate();

    useEffect(() => {
        dispatch(fetchCategories());
        dispatch(fetchTags());
    }, [])

    const handleSaveArticle = async function() {
        let timeout = setTimeout(() => {
            dispatch(setIsLoading(true));
        }, 250);
        let articleCopy;
        try {
            validateArticle();
            articleCopy = {...newArticle};
            if (files.length > 0) {
                const fileData = await uploadAllFiles();
                articleCopy['files'] = fileData;
            }
        } catch(e) {
            if (e.msg) {
                dispatch(updateErrorMessage(e.msg));
            } else if (e.status == 403) {
                dispatch(handleForbiddenStatus());
            } else {
                dispatch(updateErrorMessage("Error Saving Article"));
            }
            clearTimeout(timeout);
            dispatch(setIsLoading(false));
            return;
        }
        const createdArticle = await dispatch(addArticle(articleCopy));
        clearTimeout(timeout);
        dispatch(setIsLoading(false));
        if (createdArticle.payload?.data?._id) {
            handleArticleChange({title: ""});
            nav('/view-article/' + createdArticle.payload.data._id);
        }
    }

    const handleArticleChange = async function(article) {
        dispatch(updateNewArticle(article));
    }

    const handleFileUpload = async function(uploadedFiles) {
        
        let temp = [...files];
        [...uploadedFiles].forEach((file) => {
            temp.push(file);
        })
        setFiles(temp);
    }

    const handleFileDelete = async function(file, index) {
        let temp = [...files];
        if (temp.length == 1) {
            temp = [];
        } else {
            temp.splice(index, 1);
        }
        setFiles(temp);
    }

    const uploadAllFiles = async function() {
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append("file" + i, files[i]);
        }
        const req = await uploadFiles(formData);
        return req.data;
    }

    const validateArticle = function() {
        if (!newArticle.title || newArticle.title.length == 0) {
            throw { msg: "Please add a title" }
        }
        if (!newArticle.category) {
            throw { msg: "Please add a category" }
        }
        if (!newArticle.tags || newArticle.tags.length == 0) {
            throw { msg: "Please add at least one tag" }
        }
        if (!newArticle.markdown || newArticle.markdown.length == 0) {
            throw { msg: "Please add an article body" }
        }
    }

    const handlePhotoEmbed = async function(file) {
        let timeout = setTimeout(() => {
            dispatch(setIsLoading(true));
        }, 250);
        try {
            let formData = new FormData();
            formData.append("photo", file);
            const req = await uploadPhoto(formData);
            clearTimeout(timeout);
            dispatch(setIsLoading(false));
            await dispatch(addPhotoToNewArticle(req.data.photoId));
            return process.env.REACT_APP_API_URL + '/api/getPhoto/' + req.data.photoId;
        } catch(e) {
            if (e.msg) {
                dispatch(updateErrorMessage(e.msg))
            } else if (e.status == 403) {
                dispatch(handleForbiddenStatus());
            } else {
                console.log(e);
                dispatch(updateErrorMessage("Error Uploading Photo"));
            }
            clearTimeout(timeout);
            dispatch(setIsLoading(false));
        }
    }

    return (
        <div style={{marginTop: "20px"}}>
            <EditArticle
                files={files}
                article={newArticle}
                categories={categories}
                tags={tags}
                onSave={handleSaveArticle}
                onArticleChange={handleArticleChange}
                onFileUpload={handleFileUpload}
                onFileDelete={handleFileDelete}
                onPhotoEmbed={handlePhotoEmbed}
            ></EditArticle>
        </div>
    )
}

export default CreateArticle;