import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loginAsync, logoutAsync, toggleGlobalNotificationSettingAsync } from '../api/authApi';

const initialState = { 
    isLoggedIn: localStorage.getItem("isLoggedIn") === "true" ? true : false,
    userId: localStorage.getItem("userId"),
    email: localStorage.getItem("email"),
    userType: localStorage.getItem("userType"),
    slackConfigured: localStorage.getItem("slackConfigured") === "true" ? true : false,
    notificationSettings: localStorage.getItem("notificationSettings") ? JSON.parse(localStorage.getItem("notificationSettings")) : null
}


export const login = createAsyncThunk(
    'auth/login',
    async (data, thunkApi) => {
        try {
            const response = await loginAsync(data);
            return response;
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const logout = createAsyncThunk(
    'auth/logout',
    async (data, thunkApi) => {
        try {
            const response = await logoutAsync();
            return response;
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const toggleGlobalNotificationSetting = createAsyncThunk(
    'auth/toggleGlobalNotificationSetting',
    async (data, thunkApi) => {
        try {
            const response = await toggleGlobalNotificationSettingAsync(data);
            return {
                data,
                msg: response.msg
            }
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        handleForbiddenStatus: (state, action) => {
            localStorage.clear();
            state.isLoggedIn = false;
            state.email = "";
            state.userType = "";
            state.userId = "";
            window.location.href = "/login";
        },
        updateSlackConfigured: (state, action) => {
            state.slackConfigured = action.payload;
            localStorage.setItem("slackConfigured", action.payload);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action) => {
                state.isLoggedIn = true;
                localStorage.setItem("isLoggedIn", "true");

                state.email = action.payload.data.email;
                localStorage.setItem("email", action.payload.data.email);

                state.userType = action.payload.data.userType;
                localStorage.setItem("userType", action.payload.data.userType);

                state.slackConfigured = action.payload.data.slackConfigured;
                localStorage.setItem("slackConfigured", action.payload.data.slackConfigured);

                state.userId = action.payload.data._id;
                localStorage.setItem("userId", action.payload.data._id);

                state.notificationSettings = action.payload.data.notificationSettings;
                localStorage.setItem("notificationSettings", JSON.stringify(action.payload.data.notificationSettings));

                if (action.payload.isNew) {
                    // if the user is new, redirect them to the how to use article
                    window.location.href = "/view-article/62716f5e02b08fa3fe82c6d4"
                }
            })
            .addCase(logout.fulfilled, (state) => {
                state.isLoggedIn = false;
                state.email = "";
                state.userType = "";
                localStorage.clear();
            })
            .addCase(toggleGlobalNotificationSetting.fulfilled, (state, action) => {
                const key = action.payload.data.key;
                const targetValue = !action.payload.data.currValue;

                let curr = {...state.notificationSettings};
                curr[key] = targetValue;
                localStorage.setItem("notificationSettings", JSON.stringify(curr));
                state.notificationSettings = curr;
            })
    }
})

export const { handleForbiddenStatus, updateSlackConfigured } = authSlice.actions;
export default authSlice.reducer;