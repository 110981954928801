import {
    Link,
} from "react-router-dom";
import "./ListArticles.css";
import {FaLock} from "react-icons/fa";
import {dateFormatter} from "../../../utils/dateUtils.js";

function ListArticles(props) {
    
    return (
        props.isGrid 
        ? <>
            <div className="articles-grid-container">
                {props.articles.map((article, index) => {
                    return (
                        <Link to={"/view-article/" + article._id} className="link-container" draggable="false" key={index}>
                            <div className="search-result-container">
                                <div className="header">
                                    <h2>{article.title} {!article.isPublic && <span className="internal-flag"><FaLock></FaLock></span>}</h2>
                                    {article.createdBy?.name && <p className="sub-header">{article.createdBy.name} - {article.createdBy.email}</p>}
                                    <span className="category">{article.category?.name}</span>
                                </div>
                                <div className="tag-container">
                                    {article.tags?.map((tag) => <span key={tag._id} className="tag">{tag.name}</span>)}
                                </div>
                            </div>
                        </Link>
                    )
                })}
            </div>
        </>
        : <>
            {props.articles.map((article, index) => {
                return (
                    <Link to={"/view-article/" + article._id} className="link-container" draggable="false" key={index}>
                        <div className="search-result-container">
                            <div className="header">
                                <div>
                                    <h2>{article.title} {!article.isPublic && <span className="internal-flag"><FaLock></FaLock></span>}</h2>
                                    <p className="sub-header">
                                       Last updated {dateFormatter(article.updatedAt)}
                                       {article.createdBy?.name && <span> - {article.createdBy.name} - {article.createdBy.email}</span>}
                                    </p>
                                </div>
                                <span className="category">{article.category?.name}</span>
                            </div>
                            <div className="tag-container">
                                {article.tags?.map((tag) => <span key={tag._id} className="tag">{tag.name}</span>)}
                            </div>
                        </div>
                    </Link>
                )
            })}
        </>
    )
}

export default ListArticles;