import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import TwoChoiceModal from '../../Modals/TwoCoiceModal/twoChoiceModal';
import { updateErrorMessage, updateInfoMessage } from '../../Redux/messageSlice';
import { revokeSlackAsync } from '../../api/authApi';
import { updateSlackConfigured } from '../../Redux/authSlice';
import { getNotificationSubscriptions } from '../../Redux/notificationsSlice';
import NotificationViewer from '../../Components/Notifications/NotificationViewer/notificationViewer';
import NotificationSettings from '../../Components/Notifications/NotificationSettings/notificationSettings';
import FavoriteArticleList from '../../Components/ArticleComponents/FavoriteArticleList/favoriteArticleList';

export default function Profile() {

    const [currentTab, setCurrentTab] = useState("slack");
    const [showModal, setShowModal] = useState(false);
    const notificationSettings = useSelector((state) => state.auth.notificationSettings);
    const favoriteArticles = useSelector((state) => state.user.articleFavorites);
    const slackConfigured = useSelector((state) => state.auth.slackConfigured);
    const notificationSubscriptions = useSelector((state) => state.notifications.notificationSubscriptions);
    const dispatch = useDispatch();

    useEffect(async () => {
        dispatch(getNotificationSubscriptions())
    }, []);

    const handleRevokeConfirm = async () => {
        try {
            const req = await revokeSlackAsync();
            if (!req.error) {
                dispatch(updateSlackConfigured(false));
            }
            setShowModal(false);
            dispatch(updateInfoMessage(req.msg));
        } catch(e) {
            if (e.msg) {
                dispatch(updateErrorMessage(e.msg));
            } else {
                dispatch(updateErrorMessage("Error Revoking Access, Contact Admins"));
            }
        }
    }

    return (
        <div className="profile-container">
            <div className="admin-subnav">
                <button className={currentTab == 'slack' ? "selected" : ""} onClick={() => setCurrentTab('slack')}>Slack Config</button>
                <button className={currentTab == 'notifSettings' ? "selected" : ""} onClick={() => setCurrentTab('notifSettings')}>Notification Settings</button>
                <button className={currentTab == 'favArticles' ? "selected" : ""} onClick={() => setCurrentTab('favArticles')}>Favorite Articles</button>
            </div>
            {currentTab == 'slack' && !slackConfigured && (
                <a target="_self" href={process.env.REACT_APP_SLACK_CONNECT_URL}
                style={{alignItems: "center", color: "#000", backgroundColor: "#fff", border: "1px solid #ddd", borderRadius: "4px", display: "inline-flex", fontFamily: "Lato, sans-serif", fontSize: "16px", fontWeight: "600", height: "48px", justifyContent: "center", textDecoration: "none", width: "236px"}}><svg xmlns="http://www.w3.org/2000/svg" style={{height: "20px", width: "20px", marginRight: "12px"}} viewBox="0 0 122.8 122.8"><path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"></path><path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"></path><path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"></path><path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="#ecb22e"></path></svg>Add to Slack</a>
            )}
            {currentTab == 'slack' && slackConfigured && (
                <div style={{textAlign: "center"}}>
                    <h2 style={{fontSize: "40px"}}><AiOutlineCheckCircle style={{width: "30px", height: "30px", color: "var(--main-color)"}} /> Slack Verified</h2>
                    <button onClick={() => setShowModal(true)} className="main-button">Revoke Slack Access</button>
                </div>
            )}
            {showModal && (
                <TwoChoiceModal
                    title="Confirm Revoke"
                    message="If you revoke Slack access, you will be unable to access Slack search results unless you relink your account"
                    choiceOne="Cancel"
                    choiceTwo="Confirm"
                    onChoiceOne={() => setShowModal(false)}
                    onChoiceTwo={handleRevokeConfirm}
                ></TwoChoiceModal>
            )}
            {currentTab == 'notifSettings' && (
                <NotificationSettings
                    settings={notificationSettings}
                    subscriptions={notificationSubscriptions}
                ></NotificationSettings>
            )}
            {currentTab == 'favArticles' && (
                <FavoriteArticleList 
                    articles={favoriteArticles}
                />
            )}
        </div>
    )
}