import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { submitBrokenLinkReport } from "../../../api/resourceApi";
import { setIsLoading } from "../../../Redux/loadingSlice";
import { updateErrorMessage, updateInfoMessage } from "../../../Redux/messageSlice";
import { deleteResource, editResource } from "../../../Redux/resourcesSlice";
import GenericModal from "../../../Modals/GenericModal/genericModal";
import "../../ArticleComponents/ListArticles/ListArticles.css"
import BrokenLinkModal from "../BrokenLinkModal/brokenLinkModal";
import EditResourceModal from "../EditResourceModal/editResourceModal";
import "./resourceResults.css"
import {FaLock} from "react-icons/fa"

export default function ResourceResults(props) {
    const isAuth = useSelector((state) => state.auth.isLoggedIn);
    const userType = useSelector((state) => state.auth.userType);
    const dispatch = useDispatch();

    const [showEdit, setShowEdit] = useState(false);
    const [editingResource, setEditingResource] = useState({});
    const [showDelete, setShowDelete] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [showReport, setShowReport] = useState(false);
    const [brokenResource, setBrokenResource] = useState(null);

    const handleShowEdit = (resource) => {
        setEditingResource(resource);
        setShowEdit(true);
    }

    const handleShowDelete = (resource) => {
        const primaryAction = {
            callback: handleDeleteConfirm,
            label: "Confirm",
            args: {
                id: resource._id
            },
            isPrimary: true,
        }
        const secondaryAction = {
            callback: () => setShowDelete(false),
            label: "Cancel",
            args: {},
        };

        const modalPayload = {
            actions: [secondaryAction, primaryAction],
            title: "Confirm Deletion",
            body: <p>Please confirm you'd like to remove the "{resource.title}" resource. This action cannot be undone.</p>
        }
        setModalData(modalPayload);
        setShowDelete(true);
    }

    const handleHideEdit = () => {
        setEditingResource({});
        setShowEdit(false);
    }

    const handleSaveEdits = async (updatedResource, editMade) => {
        if (editMade) {
            let timeout = setTimeout(() => {
                dispatch(setIsLoading(true));
            }, 250);
            let req = await dispatch(editResource(updatedResource));
            clearTimeout(timeout);
            dispatch(setIsLoading(false));
            if (!req.payload?.error) {
                handleHideEdit();
            }
        } else {
            setShowEdit(false);
        }
    }

    const handleDeleteConfirm = async (args) => {
        let timeout = setTimeout(() => {
            dispatch(setIsLoading(true));
        }, 250);
        let req = await dispatch(deleteResource(args.id));
        clearTimeout(timeout);
        dispatch(setIsLoading(false));
        if (!req.payload?.error) {
            setShowDelete(false);
            setModalData(null);
        }
    }

    const handleShowReport = async (resource) => {
        setBrokenResource(resource);
        setShowReport(true);
    }

    const handleSubmitLink = async (suggestedLink) => {
        try {
            let timeout = setTimeout(() => {
                dispatch(setIsLoading(true));
            }, 250);
            let body = {
                resource: brokenResource,
                suggestedLink,
            }
            let req = await submitBrokenLinkReport(body);
            clearTimeout(timeout);
            dispatch(setIsLoading(false));
            dispatch(updateInfoMessage(req.msg));
            setShowReport(false);
            setBrokenResource(null);
        } catch(e) {
            if (e.msg) {
                dispatch(updateErrorMessage(e.msg))
            } else {
                dispatch(updateErrorMessage("Error Saving Report"));
            }
        }
        
    }

    return (
        <div style={{width: "100%"}}>
            {props.resources?.map((resource, index) => {
                return (
                    <div className="search-result-container" key={index}>
                        <a href={resource.url} target="_blank" draggable="false" className="link-container">
                            <div className="header">
                                <div>
                                    <h2>{resource.title} {!resource.isPublic && <span className="internal-flag"><FaLock/></span>}</h2>
                                    <p className="sub-header">{resource.url}</p>
                                </div>
                                <span className="category">{resource.category?.name}</span>
                            </div>
                            
                            <div style={{textAlign: "left"}}>
                                {resource.tags?.map((tag) => <span key={tag._id} className="tag">{tag.name}</span>)}
                            </div>
                            
                            <p className="description">{resource.description}</p>
                            
                        </a>
                        {isAuth && userType == "admin" && (<div className="options">
                            <button className="main-button" onClick={(e) => handleShowReport(resource)}>Report Broken Link</button> 
                            <button className="main-button" style={{backgroundColor: "var(--error-msg)", borderColor: "var(--error-msg)"}} onClick={(e) => handleShowDelete(resource)}>Delete</button>
                            <button className="main-button" style={{backgroundColor: "var(--main-color)", borderColor: "var(--main-color)"}} onClick={(e) => handleShowEdit(resource)}>Edit</button>
                        </div>)}
                    </div>
                )
            })}
            {props.resources?.length == 0 && (
                <h2>No Resources Found</h2>
            )}
            {showEdit && (
                <EditResourceModal
                    resource={editingResource}
                    onClose={handleHideEdit}
                    onSave={handleSaveEdits}
                ></EditResourceModal>
            )}
            {showDelete && (
                <GenericModal
                    modalData={modalData}
                ></GenericModal>
            )}
            {showReport && (
                <BrokenLinkModal
                    onSave={handleSubmitLink}
                    onClose={() => {
                        setShowReport(false);
                        setBrokenResource(null);
                    }}
                ></BrokenLinkModal>
            )}
        </div>
    )
}