import { createSlice} from '@reduxjs/toolkit';

const initialState = {
    errorMessage: "",
    infoMessage: "",
    messages: [],
}

function isRejectedAction(action) {
    return action.type.endsWith('rejected')
}

function isFulfilledAaction(action) {
    return action.type.endsWith('fulfilled')
}

const messagesSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        updateErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        updateInfoMessage: (state, action) => {
            state.infoMessage = action.payload;
        },
        queueMessage: (state, action) => {
            let curr = [...state.messages];
            curr.push(action.payload);
            state.messages = curr; 
        },
        popMessage: (state) => {
            let curr = [...state.messages];
            curr.shift();
            state.messages = curr; 
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isRejectedAction, (state, action) => {
                if (action.payload.msg)
                    state.errorMessage = action.payload.msg;
                if (action.payload.status == 403) {
                    localStorage.clear();
                    window.location.href = "/login";
                }
            })
            .addMatcher(isFulfilledAaction, (state, action) => {
                if (action.payload?.msg)
                    state.infoMessage = action.payload.msg;
            })
    }
})

export const { updateErrorMessage, updateInfoMessage, queueMessage, popMessage } = messagesSlice.actions;
export default messagesSlice.reducer;