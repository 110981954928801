import { useEffect, useRef, useState } from 'react';
import { MdNotifications, MdNotificationsActive } from 'react-icons/md';
import NotificationViewer from '../NotificationViewer/notificationViewer';
import { useIdleTimer } from 'react-idle-timer';
import "../notification-styles.css";
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications, setNewNotifications } from '../../../Redux/notificationsSlice';

export default function NotificationNavOption() {

    const [showDropdown, setShowDropdown] = useState(false);
    const [shouldFetch, setShouldFetch] = useState(true);
    const shouldFetchRef = useRef(null);
    shouldFetchRef.current = shouldFetch;
    const filterByUnread = useSelector((state) => state.notifications.filterByUnread);
    const notifications = useSelector((state) => state.notifications.notifications);
    const newNotifications = useSelector((state) => state.notifications.newNotifications);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchNotifications(filterByUnread);
        const interval = setInterval(() => {
            periodicFetch();
        }, 120000)
        return () => {
            clearInterval(interval)
        }
    }, []);

    const handleDropdownToggle = async () => {
        setShowDropdown(prev => !prev);
        if (!showDropdown) {
            await fetchNotifications(filterByUnread);
        } 
        dispatch(setNewNotifications(false))
    }

    const iconStyles = {
        width: "35px",
        height: "35px",
        color: "var(--dark-gray)",
        borderRadius: "10px",
    }

    const newIconStyles = {
        width: "35px",
        height: "35px",
        color: "var(--accent-orange)",
        borderRadius: "10px",
    }

    const periodicFetch = () => {
        if (shouldFetchRef.current) {
            fetchNotifications(filterByUnread);
        }
    }

    const fetchNotifications = async (filter) => {
        await dispatch(getNotifications(filter));
    }

    const onActive = () => {
        setShouldFetch(true);
        fetchNotifications(filterByUnread);
    }

    const onIdle = () => {
        setShouldFetch(false);
    }

    const idleTimer = useIdleTimer({
        onActive,
        onIdle,
        timeout: 60000,

    })

    return (
        <div className="notification-nav-container">
            <div className="icon-container" onClick={handleDropdownToggle}>
                {!newNotifications && <MdNotifications style={ iconStyles}/>}
                {newNotifications && <MdNotificationsActive style={newIconStyles}/>}
            </div>
            {showDropdown && (
                <div className='notifications-nav-dropdown'>
                    <NotificationViewer 
                        onView={() => setShowDropdown(false)}
                        onClickOutside={handleDropdownToggle}
                        filterByUnread={filterByUnread}
                        notifications={notifications}
                        fetchNotifications={fetchNotifications}
                    />
                </div>
            )}
        </div>
    )
}