import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCategoriesAsync, addCategoryAsync, deleteCategoryAsync, editCategoryAsync } from '../api/categoriesApi';

const initialState = { 
    values: [],
    status: 'idle',
}

export const fetchCategories = createAsyncThunk(
    'categories/fetchCategories',
    async (data, thunkApi) => {
        try {
            const response = await fetchCategoriesAsync();
            return response.data
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const addCategory = createAsyncThunk(
    'categories/addCategory',
    async (category, thunkApi) => {
        try {
            const response = await addCategoryAsync(category);
            return response;
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const deleteCategory = createAsyncThunk(
    'categories/deleteCategory',
    async (data, thunkApi) => {
        try {
            const req = await deleteCategoryAsync(data.id);
            return {
                index: data.index,
                msg: req.msg
            }
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const editCategory = createAsyncThunk(
    'categories/editCategory',
    async (data, thunkApi) => {
        try {
            const req = await editCategoryAsync(data.category);
            return {
                data,
                msg: req.msg,
            }
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

const categorySlice = createSlice({
  name: 'categories',
  initialState,
  extraReducers: (builder) => {
      builder
        .addCase(fetchCategories.fulfilled, (state, action) => {
            state.status = 'idle';
            state.values = action.payload;
        })
        .addCase(addCategory.fulfilled, (state, action) => {
            state.status = 'idle';
            let newCategory = action.payload.data;
            state.values = [...state.values, newCategory];
        })
        .addCase(deleteCategory.fulfilled, (state, action) => {
            let currArr = [...state.values];
            if (action.payload.index == 0) {
                currArr.shift();
            } else {
                currArr.splice(action.payload.index, 1);
            }
            state.values = currArr;
        })
        .addCase(editCategory.fulfilled, (state, action) => {
            let currArr = [...state.values];
            currArr[action.payload.data.index] = action.payload.data.category;
            state.values = currArr;
        })
  }
})

export const {  } = categorySlice.actions
export default categorySlice.reducer 