import { checkStatusCode } from "./utils";

export async function fetchTagsAsync() {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/getTags' , {
        credentials: "include",
    });
    const res = await req.json();
    if (res.error) throw res;
    return res;
}

export async function addTagAsync(tag) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/saveTag', {
        method: "POST",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(tag),
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res;
}

export async function deleteTagAsync(id) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/deleteTag/'+id, {
        method: "DELETE",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res; 
}

export async function editTagAsync(tag) {
    const req = await fetch(process.env.REACT_APP_API_URL + '/api/editTag', {
        method: "PUT",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(tag),
    });
    checkStatusCode(req);
    const res = await req.json();
    if (res.error) throw res;
    return res; 
}