
import "./simpleSwitch.css";
export default function SimpleSwitch(props) {

    const toggleSwitch = () => {
        let curr = props.target;
        !curr ? props.onToggle(true) : props.onToggle(false);
    }

    return (
        <div className="switch-container">
            <div className="switch-option">
                <button 
                    className={!props.target ? "switch-on" : ""} 
                    onClick={props.target ? toggleSwitch : null}
                    style={{borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px"}}
                >{props.labelOne}</button>
            </div>
            <div className="switch-option">
                <button 
                    className={props.target ? "switch-off" : ""} 
                    onClick={!props.target ? toggleSwitch : null}
                    style={{borderTopRightRadius: "5px", borderBottomRightRadius: "5px"}}
                >{props.labelTwo}</button>
            </div>
        </div>
    )
}