import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./messageReporter.css";
import { popMessage, queueMessage, updateErrorMessage, updateInfoMessage } from "../../Redux/messageSlice";

export default function MessageReporter() {
    const errorMessage = useSelector((state) => state.messages.errorMessage);
    const infoMessage = useSelector((state) => state.messages.infoMessage);
    const messages = useSelector((state) => state.messages.messages);
    const dispatch = useDispatch();

    useEffect(async () => {
        if (errorMessage.length > 0) {
            await dispatch(queueMessage({
                type: "error",
                text: errorMessage,
            }))
            dispatch(updateErrorMessage(""));
            setTimeout(() => {
                dispatch(popMessage());
            }, 3500)
        }
    }, [errorMessage]);

    useEffect(async () => {
        if (infoMessage.length > 0) {
            await dispatch(queueMessage({
                type: "info",
                text: infoMessage,
            }))
            dispatch(updateInfoMessage(""));
            setTimeout(() => {
                dispatch(popMessage());
            }, 3500)
        }
    }, [infoMessage]);

    const handleClick = () => {
        dispatch(popMessage());
    }

    return (
        <div className="messages-container">
            {messages?.length > 0 && messages.map((msg, index) => {
                if (msg.type == "error") {
                    return (
                        <div className="message-container error-message" key={index} onClick={() => handleClick(index)}>
                            <h1>Error</h1>
                            <p>{msg.text}</p>
                        </div>
                    )
                } else if (msg.type == "info") {
                    return (
                        <div className="message-container info-message" key={index} onClick={() => handleClick(index)}>
                            <h1>Info</h1>
                            <p>{msg.text}</p>
                        </div>
                    )
                }
            })}
        </div>
    )
}