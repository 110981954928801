import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { deleteResourceAsync, editResourceAsync, fetchBrokenReportsAsync, deleteReportAsync, updateResourceUrlAsync } from '../api/resourceApi';
import { logout } from "./authSlice";

const initialState = {
    newResource: { },
    brokenReports: [],
}

export const editResource = createAsyncThunk(
    'resources/editResource',
    async (data, thunkApi) => {
        try {
            const response = await editResourceAsync(data);
            return {
                data,
                msg: response.msg,
            }
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const deleteResource = createAsyncThunk(
    'resources/deleteResource',
    async (id, thunkApi) => {
        try {
            const response = await deleteResourceAsync(id);
            return {
                id,
                msg: response.msg
            }
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const fetchBrokenReports = createAsyncThunk(
    'resources/fetchBrokenReports',
    async (data, thunkApi) => {
        try {
            const response = await fetchBrokenReportsAsync();
            return response;
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const deleteReport = createAsyncThunk(
    'resources/deleteReport',
    async (id, thunkApi) => {
        try {
            const response = await deleteReportAsync(id);
            return {
                msg: response.msg,
                id,
            }
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

export const updateResourceUrl = createAsyncThunk(
    'resources/updateResourceUrl',
    async (data, thunkApi) => {
        try {
            const response = await updateResourceUrlAsync(data);
            return {
                data,
                msg: response.msg,
            };
        } catch(e) {
            return thunkApi.rejectWithValue(e);
        }
    }
)

const resourceSlice = createSlice({
    name: 'resources',
    initialState,
    reducers: {
        updateNewResource: (state, action) => {
            state.newResource = action.payload; 
        },
        clearNewResource: (state, action) => {
            state.newResource = {};
        }
    }, 
    extraReducers: (builder) => {
        builder
        .addCase(fetchBrokenReports.fulfilled, (state, action) => {
            state.brokenReports = action.payload.data;
        })
        .addCase(editResource.fulfilled, (state, action) => {
            let resourceId = action?.payload?.data?._id;
            let index = state.brokenReports.findIndex((report) => report._id == resourceId);
            if (index > -1) {
                let temp = [...state.brokenReports];
                temp[index] = action.payload.data;
                state.brokenReports = temp;
            }
        })
        .addCase(deleteReport.fulfilled, (state, action) => {
            let index = state.brokenReports.findIndex((report) => report._id == action.payload.id);
            if (index > -1) {
                let temp = [...state.brokenReports];
                temp.splice(index, 1);
                state.brokenReports = temp;
            }
        })
        .addCase(updateResourceUrl.fulfilled, (state, action) => {
            let index = state.brokenReports.findIndex((report) => report._id == action.payload.data.reportId);
            if (index > -1) {
                let temp = [...state.brokenReports];
                temp.splice(index, 1);
                state.brokenReports = temp;
            }
        })
        .addCase(logout.fulfilled, (state, action) => {
            state.newResource = {};
            state.brokenReports = [];
        })
    }
})

export const {
    updateNewResource,
    clearNewResource
} = resourceSlice.actions;
export default resourceSlice.reducer;