// takes in a list of replies and presents them
import Comment from "../Comment/comment"
import "../comment-styles.css";
export default function CommentReplies(props) {
    return (
        <div className="replies-container">
            {props.replies?.map((reply, index) => {
                return (<Comment
                    key={index}
                    comment={reply}
                    isTopLevel={false}
                    onDelete={() => props.onDelete(reply, index)}
                    onEdit={() => props.onEdit(reply, index)}
                ></Comment>)
            })}
        </div>
    )
}