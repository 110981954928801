// Takes in a comment and presents it
// If it's a top level comment, allows user to reply
// shows all replies in a collapsible window beneath the main comment

import { useState } from "react";
import Editor from "react-markdown-editor-lite";
import { useSelector } from "react-redux";
import FileLister from "../../FileLister/fileLister";
import MarkdownViewer from "../../MarkdownViewer/MarkdownViewer";
import CommentReplies from "../CommentReplies/commentReplies";
import "../comment-styles.css"

export default function Comment(props) {

    const [isCollapsed, setIsCollapsed] = useState(true);

    const userEmail = useSelector((state) => state.auth.email);
    const userType = useSelector((state) => state.auth.userType);

    return (
        <div className="comment-container">
            <p className="comment-header">{props.comment?.createdBy} - {new Date(props.comment?.postedAt).toLocaleString()}</p>
            <div className="comment-subheader">
                <p>{props.comment?.editedAt ? "Edited At - " + new Date(props.comment?.editedAt).toLocaleString() : ""}</p>
                <div className="comment-options">
                    {props.isTopLevel && <button onClick={props.onReply} className="main-button">Reply</button>}
                    {
                        (userEmail == props.comment?.createdBy || userType == "admin") && 
                        <button className="main-button" onClick={props.onDelete}>Delete</button> 
                    }
                    {
                        (userEmail == props.comment?.createdBy) && 
                        <button className="main-button" onClick={props.onEdit}>Edit</button> 
                    }
                </div>
            </div>
            
            <Editor
                style={{border: "none"}}
                value={props.comment?.markdown}
                view={{menu: false, md: false, html: true}}
                renderHTML={(text) => <MarkdownViewer markdown={text} />}
            />
            <FileLister
                showDownload={true}
                files={props.comment.files}
                reqBaseUrl={process.env.REACT_APP_API_URL + '/api/file/comment?path='}
            ></FileLister>
            
            {!isCollapsed && props.comment?.replies?.length > 0 && (
                <p className="replies-label" style={{borderBottom: "1px solid var(--accent-gray)"}}><button onClick={() => setIsCollapsed(true)} >- Hide Replies ({props.comment?.replies?.length})</button></p>
            )}
            {props.comment?.replies?.length > 0 && !isCollapsed && (
                <CommentReplies
                    replies={props.comment.replies}
                    onDelete={(reply, index) => props.onDeleteReply(reply, index)}
                    onEdit={(reply, index) => props.onEditReply(reply, index)}
                ></CommentReplies>
            )}
            {isCollapsed && props.comment?.replies?.length > 0 && (
                <p className="replies-label"><button onClick={() => setIsCollapsed(false)} >+ Show Replies ({props.comment?.replies?.length})</button></p>
            )}
            {!isCollapsed && props.comment?.replies?.length > 0 && (
                <p className="replies-label" style={{borderTop: "1px solid var(--accent-gray)"}}><button onClick={() => setIsCollapsed(true)} >- Hide Replies ({props.comment?.replies?.length})</button></p>
            )}
        </div>
    )
}